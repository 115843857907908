<template>
  <div class="login container d--flex d--flex-center">
    <div class="login__form-wrapper" v-if="!adBlocker">
      <h1 class="headline headline--main">{{ labels?.login?.headline }}</h1>
      <form action="/" novalidate @submit.prevent="onFormSubmit">
        <BaseTextInput
            v-model="email"
            @blur="v$.email.$validate()"
            :validator="v$.email"
            type="email"
            :label="labels?.login?.email"
            name="email"
            class="mt--3"
        />
        <BaseTextInput
            v-model="v$.password.$model"
            :validator="v$.password"
            type="password"
            :label="labels?.login?.password"
            name="password"
            class="mt--3"
        />
        <div v-if="errorMessage" class="mt--3">
          <p class="form-field__error-message">{{ errorMessage }}</p>
        </div>
        <BaseButton
            type="submit"
            :text="labels?.login?.button"
            class="button--login mt--3"
            :class="errorMessage ? 'mt--3' : 'mt--5'"
        />
        <div  class="login__link-wrapper mt--3">
          <Link :text="labels?.login?.passwordReset" url="/reset-password"/>
        </div>
      </form>
    </div>
    <div class="ad-blocker-error" v-else>
      <h3>Bitte deaktivieren Sie Ihren Adblocker</h3>
      <span
      >Ihr Adblocker blockiert wichtige Funktionen des BurdaForward
        Unternehmens-Advertorial Dashboard.</span
      >
      <span
      >Bitte whitelisten Sie das BurdaForward Unternehmens-Advertorial
        Dashboard in Ihrem Adblocker oder deaktivieren Sie den Adblocker.</span
      >
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import BaseTextInput from "@/components/form/BaseTextInput";
import BaseButton from "@/components/elements/BaseButton";
import axios from "axios";
import Link from "@/components/elements/Link.vue";

export default {
  name: "Login",
  components: {Link, BaseButton, BaseTextInput},
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  beforeCreate() {
    axios
        .head("/api/advertorials/0")
        .then(() => {
        })
        .catch((err) => {
          if (!err.response) {
            this.adBlocker = true;
          }
        });
  },
  data() {
    return {
      adBlocker: false,
      email: null,
      password: null,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getLoginStatus",
    }),
    ...mapState("common", ["labels"]),
  },
  methods: {
    ...mapActions("user", ["login"]),
    async onFormSubmit() {
      this.errorMessage = null;
      const isValid = await this.v$.$validate();
      if (!isValid) return;
      const result = await this.login({
        username: this.email,
        password: this.password,
      });
      this.errorMessage = result?.errorMessage;
      if (this.isLoggedIn) {
        await this.$router.push("/");
      }
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
.login {
  flex 1

  &__form-wrapper {
    width (100% / 3)
  }
  &__link-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em 1.5em;
    a{
      &:hover{

      }
    }
  }

}

.ad-blocker-error {
  gap 5px
  width (100% / 2)
  background-color: #ededed;
  padding: 20px 20px 40px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(12, 0, 51, .1);
  display flex
  flex-direction column
}


</style>

<template>
  <modal ref="popup" @click="outsideClick">
    <div class="modal--container">
      <div class="modal--header">
        <h2 class="modal__title">{{ titleText }}</h2>
        <button @click="interrupt" class="button-close">
          <svg-icon icon="times" class="icon-close" />
        </button>
      </div>
      <div class="modal--body">
        <p class="modal__text">{{ mainText }}</p>
      </div>
      <div class="button--wrapper modal--footer">
        <button class="modal__cancel-button" @click="cancel">
          {{ cancelText }}
        </button>
        <button class="modal__confirm-button" @click="confirm">
          {{ confirmText }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "unsavedChangeModal",
  components: { SvgIcon, Modal },
  data() {
    return {
      titleText: undefined,
      mainText: undefined,
      confirmText: undefined,
      cancelText: undefined,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    show(opts = {}) {
      this.titleText = opts.titleText;
      this.mainText = opts.mainText;
      this.confirmText = opts.confirmText;
      this.cancelText = opts.cancelText;

      this.$refs.popup.open();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    interrupt(){
      this.$refs.popup.close();
      this.resolvePromise("interrupt");
    },
    outsideClick() {
      if (event.target == document.querySelector(".modal--backdrop")) {
        this.interrupt();
      }
    },
  },
};
</script>

<style scoped>
.modal--container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 11;
  background-color: #ffffff;
  width: 35%;
  gap: 30px;
  max-height: 90vh;
  padding: 30px;
  border-radius: 6px;
}

.button--wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.modal--header {
  display: flex;
  position: relative;
}

.modal__title {
}

.modal__text {
}

.modal__cancel-button {
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1rem;
  background-color: #d61600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  min-height: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.modal__cancel-button:hover {
  background-color: #2e2e2e;
}

.modal__confirm-button {
  margin-left: 20px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1rem;
  background-color: #95c11c;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  min-height: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.modal__confirm-button:hover {
  background-color: #2e2e2e;
}

.button-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  margin-left: auto;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

.button-close:hover {
  color: #95c11c;
}

.icon-close {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <RouterLink :to="url" :target="target" class="link">
    <SvgIcon v-if="icon" :icon="icon" class="link__icon" />
    <span class="link__text">{{ text }}</span>
  </RouterLink>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "Link",
  components: { SvgIcon },
  props: {
    url: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="stylus">
.link {
  display inline-flex
  align-items center
  cursor pointer
  transition color $duration-transition-hover-default ease-in-out

  &__text {
    position relative

    &::after {
      content ''
      position absolute
      width 0
      height 1px
      background-color currentColor
      bottom -1px
      left 50%
      transform translateX(-50%)
      transition width $duration-transition-hover-default ease-in-out

      ^[0]:hover &,
      ^[0]--active & {
        width: 100%;
      }
    }
  }

  &__icon {
    margin-right 8px
    flex 0 0 auto
  }

  &--action {
    color $color-brand-green
    font-size: $font-size.xs
    text-transform uppercase
    font-weight $font-weight-bold
    white-space nowrap

    &:hover {
      color $color-brand-green
    }
  }

  &--table {
    & ^[0]__text {
      text-decoration underline
      text-underline-offset 5px
      &::after {
        display none
      }
    }

    &:hover {
      color $color-brand-green
    }
  }
}
</style>

import axiosInstance from "@/utils/axiosInstance";

const state = {
    teamMessages: [],
    advertorialMessages: {},
};

const getters = {
    getMessagesByAdvertorialId: (state) => (advertorialId) => {
        return state.advertorialMessages[advertorialId];
    },
};

const actions = {
    async fetchTeamMessages(
        {commit, rootGetters},
        {teamId = rootGetters["user/getCurrentTeam"]?.id}
    ) {
        try {
            const result = await axiosInstance.get("messages", {
                params: {
                    teamId,
                },
            });
            commit("SET_TEAM_MESSAGES", result.data);
        } catch (e) {
            console.warn("fetchMessages failed", e);
        }
    },
    async fetchAdvertorialMessages({commit}, {advertorialId}) {
        try {
            const result = await axiosInstance.get("messages", {
                params: {
                    advertorialId,
                },
            });
            commit("SET_ADVERTORIAL_MESSAGES", {
                messages: result.data,
                advertorialId,
            });
        } catch (e) {
            console.warn("fetchMessages failed", e);
        }
    },
    async postMessage({commit, dispatch}, {advertorialId, message}) {
        dispatch("ui/toggleLockLayer", true, {root: true});
        try {
            const result = await axiosInstance.post(`messages`, {
                advertorialId,
                message,
            });
            commit("ADD_MESSAGE", {message: result.data, advertorialId});
            dispatch("ui/toggleLockLayer", false, {root: true});
            return result;
        } catch (e) {
            console.warn("postMessage failed", e);
            dispatch("ui/toggleLockLayer", false, {root: true});
        }
    },
};

const mutations = {
    SET_TEAM_MESSAGES: (state, payload) => {
        state.teamMessages = payload;
    },
    SET_ADVERTORIAL_MESSAGES: (state, payload) => {
        state.advertorialMessages[payload.advertorialId] = payload.messages;
    },
    ADD_MESSAGE: (state, payload) => {
        state.advertorialMessages[payload.advertorialId] = [...state.advertorialMessages[payload.advertorialId], payload.message];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

const toDateString = (
  dateString,
  options = { convertToMilliSeconds: false, locale: "de-DE" }
) => {
  if (!dateString) return "-";
  if (!(dateString instanceof Date)) {
    dateString = new Date(
      options.convertToMilliSeconds ? dateString * 1000 : dateString
    );
  }
  return dateString.toLocaleDateString(options.locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const toDateStringWithTime = (
  dateString,
  options = {
    convertToMilliSeconds: false,
    locale: "de-DE",
    timeKeyword: "", //e.g. "Uhr"
  }
) => {
  if (!(dateString instanceof Date)) {
    dateString = new Date(
      options.convertToMilliSeconds ? dateString * 1000 : dateString
    );
  }
  return `${dateString.toLocaleDateString(options.locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })} ${dateString.toLocaleTimeString(options.locale, {
    hour: "2-digit",
    minute: "2-digit",
  })}${options.timeKeyword ? `&nbsp;${options.timeKeyword}` : ""}`;
};

export { toDateString, toDateStringWithTime };

<template>
  <component
    :is="url ? 'RouterLink' : 'button'"
    :to="url"
    :type="url ? null : type"
    class="button"
  >
    <SvgIcon
      v-if="icon && iconPosition === 'left'"
      :icon="icon"
      class="button__icon"
    />
    {{ text }}
    <SvgIcon
      v-if="icon && iconPosition === 'right'"
      :icon="icon"
      class="button__icon button__icon--next"
    />
  </component>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
export default {
  name: "BaseButton",
  components: { SvgIcon },
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    target: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "button",
    },
    url: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="stylus" scoped>
$height-button-default = px-to-rem(60px)
$height-button-medium = px-to-rem(40px)
$height-button-small = px-to-rem(30px)

.button {

  display flex
  justify-content center
  align-items center
  text-align center
  width 100%
  min-height $height-button-default
  padding 4px $border-radius-button-default
  border-radius $border-radius-button-default
  font-size: $font-size.m
  font-weight $font-weight-bold
  background-color $color-brand-green
  color $color-text-inverted
  text-transform uppercase
  white-space nowrap
  cursor pointer
  transition background-color $duration-transition-hover-default ease-in-out,
  color $duration-transition-hover-default ease-in-out

  &__icon {
    margin-right 15px

    &--prev {
      margin-right 10px
    }

    &--next {
      margin-right 0
      margin-left 10px
    }
  }

  &:hover {
    background-color $color-anthracite
  }

  &--inline {
    display inline-flex
    width auto
  }

  &--single-line {
    white-space nowrap
  }

  &--angular {
    border-radius $border-radius-default
  }

  &--icon-only {
    padding 0
    border-radius 50%
    min-height 0
    width $height-button-medium
    height $height-button-medium

    & ^[0]__icon {
      margin 0
    }
  }

  &--back-to-top {
    background-color $anthracite
    opacity 0.3
    font-size px-to-rem(24px)
    transition opacity $duration-transition-hover-default ease-in-out

    &:hover {
      opacity 0.6
    }
  }

  &--add-option {
    //@extend .button--black
    flex 0 0 auto
    width px-to-rem(60px)
    height 100%
    border-radius 0 2px 2px 0
    padding 0

    & ^[0]__icon {
      margin 0
    }
  }

  &--copy-url {
    @extend .button--add-option
    width px-to-rem(40px)
    min-height px-to-rem(40px)
  }

  &--delete {
    width 60px
    height 60px
    font-size 18px
    background-color $white
    border-radius 0
    border 1px solid $color-border-default
    color $anthracite
    padding 0

    &:hover {
      background-color $white
      color $color-brand-green
    }
  }

  &--medium,
  &--small {
    border-radius $border-radius-button-medium
    padding-left $border-radius-button-medium
    padding-right $border-radius-button-medium

    & ^[0]__icon {
      margin-right 10px
    }
  }

  &--medium {
    min-height $height-button-medium
    font-size: $font-size.s
  }

  &--small {
    min-height $height-button-small
    font-size: $font-size.xs
  }

  &--black {
    background-color $color-anthracite

    &:hover,
    ^[0]--hover & {
      background-color $color-brand-green
    }
  }

  &--login {
    @extend .button--black
    &:hover {
      background-color $color-anthracite
      text-decoration underline
    }
  }

  &--red {
    background-color $color-bright-red
  }

  &--disabled {
    background-color $color-lighter-gray
    color $color-dark-gray
    pointer-events none
  }

  &--error {
    border 1px solid $color-form-error
  }

}
</style>

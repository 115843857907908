<template>
  <div class="filter-checkbox-group">
    <BaseCheckbox
      v-for="option in sortedOptions"
      :key="`filter-${filterKey}-${option}`"
      :inputValue="option.value"
      v-show="option.selectable"
      v-model="model"
    >
      <template v-if="filterKey === 'dashboardStatus'">
        <Status :status="option.value" />
      </template>
      <template v-else>
        {{ option.name }}
      </template>
    </BaseCheckbox>
  </div>
</template>

<script>
import BaseCheckbox from "@/components/form/BaseCheckbox";
import Status from "@/components/elements/Status";
import { sortAlphabetically } from "@/utils/sort";

export default {
  name: "FilterCheckboxGroup",
  components: { Status, BaseCheckbox },
  props: {
    filterKey: {
      type: String,
      default: null,
      required: true,
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    sortAlphabetically: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    sortedOptions() {
      return this.sortAlphabetically
        ? sortAlphabetically([...this.options], "name")
        : this.options;
    },
  },
};
</script>

<style lang="stylus" scoped>
.filter-checkbox-group {

}
</style>

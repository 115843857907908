<template>
  <section class="messages mt--6">
    <h3 class="headline headline--section">{{ labels?.messages?.headline }}</h3>
    <TextAccordion
      v-cloak
      class="text-accordion--messages mt--2"
      closed-height="146px"
      default-overflow="auto"
      @accordionToggled="(val) => (accordionIsOpen = val)"
    >
      <table
        class="messages__table"
        :style="renderedMessages.length ? '' : 'min-height: 146px'"
      >
        <tr
          class="messages__table-row"
          :ref="setMessageRef"
          v-for="(message, index) in renderedMessages"
          :key="`message-${index}`"
        >
          <td class="messages__message">
            <span
              class="message"
              :class="{ 'message--warning': message.warning }"
            >
              <SvgIcon
                :icon="messageTypeIcon(message.type)"
                class="message__icon"
              />
              {{ message.message }}
            </span>
          </td>
          <td class="messages__date">
            {{ toDateStringWithTime(message.createdAt) }}
          </td>
          <td class="messages__link">
            <Link
              v-if="message.advertorialId"
              :url="messageLink(message.advertorialId)"
              :text="labels?.messages?.linkText"
              icon="angle-right"
              class="link--action"
            />
          </td>
        </tr>
      </table>
    </TextAccordion>
  </section>
</template>

<script>
import TextAccordion from "../common/TextAccordion";
import messagesStore from "@/store/modules/messages";
import { mapActions, mapGetters, mapState } from "vuex";
import SvgIcon from "@/components/common/SvgIcon";
import Link from "@/components/elements/Link";
import formatStringMixin from "@/mixins/formatStringMixin";
import { MESSAGE_TYPE_ICONS } from "@/constants";

const INITIAL_MESSAGES_MAX_COUNT = 10;

export default {
  name: "Messages",
  components: { Link, SvgIcon, TextAccordion },
  mixins: [formatStringMixin],
  props: {},
  data() {
    return {
      renderedMessages: [],
      messageRefs: [],
      accordionHasBeenOpened: false,
      accordionIsOpen: false,
    };
  },
  computed: {
    ...mapState("messages", {
      messages: "teamMessages",
    }),
    ...mapState("common", ["labels"]),
    ...mapGetters("user", {
      currentTeam: "getCurrentTeam",
    }),
  },
  watch: {
    accordionIsOpen(newValue) {
      if (
        newValue &&
        !this.accordionHasBeenOpened &&
        this.renderedMessages.length < this.messages.length
      ) {
        this.increaseRenderedMessages(20);
        this.accordionHasBeenOpened = true;
      }
    },
    messages(newValue) {
      if (newValue?.length) {
        this.renderedMessages = newValue.slice(0, INITIAL_MESSAGES_MAX_COUNT);
      }
    },
    renderedMessages(newValue) {
      if (newValue.length < this.messages.length) {
        this.$nextTick(() => this.addIntersectionObserver());
      }
    },
  },
  beforeMount() {
    if (!this.$store.hasModule("messages")) {
      this.$store.registerModule("messages", messagesStore);
    }
    this.fetchTeamMessages({ teamId: this.currentTeam?.id });
  },
  methods: {
    ...mapActions("messages", ["fetchTeamMessages"]),
    addIntersectionObserver() {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            this.increaseRenderedMessages();
            observer.unobserve(target);
          }
        },
        {
          root: this.accordionIsOpen ? document : this.$el.parentNode,
        }
      );
      const target = this.messageRefs[this.renderedMessages.length - 2];
      if (target) {
        observer.observe(target);
      }
    },
    increaseRenderedMessages(
      messagesToAddCount = this.accordionIsOpen ? 20 : 10
    ) {
      this.renderedMessages = this.messages.slice(
        0,
        this.renderedMessages.length + messagesToAddCount
      );
    },
    setMessageRef(el) {
      this.messageRefs.push(el);
    },
    messageTypeIcon(type) {
      return MESSAGE_TYPE_ICONS[type] || "info-circle";
    },
    messageLink(advertorialId) {
      const targetView = "briefing"; //TODO: Make this dependant on message type or advertorial status
      return `/advertorial/${advertorialId}/${targetView}`;
    },
  },
};
</script>

<style lang="stylus" scoped>

.messages {

  table-default()

  &__message {
    width 70%
  }

  &__date {
    white-space nowrap
    color $color-text-gray
  }

  &__link {
    white-space nowrap
  }

  &__message-wrapper {
    display flex
    align-items center
  }
}

.message {

  display flex
  align-items center
  white-space pre-line

  &__icon {
    margin-right 16px
  }

  &--warning {
    color $color-form-error
  }
}
</style>

import { mapState } from "vuex";

const validateFileMixin = {
  props: {
    restrictions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      errors: [],
      warnings: [],
    };
  },
  computed: {
    ...mapState("common", ["config"]),
    fileRestrictions() {
      return {
        ...(this.config?.fileRestrictions || {}),
        ...this.restrictions,
      };
    },
  },
  methods: {
    validateFileMimeType(file) {
      const valid = this.fileRestrictions.mimeTypes.some((mimeType) =>
        file.type.match(mimeType)
      );
      if (!valid) {
        this.errors.push("mimeType");
      }
      return valid;
    },
    isMedia(file) {
      const valid = ['video/mpeg', 'video/mp4', 'video/quicktime', 'video/webm',"image/jpg", "image/jpeg", "image/png"].some((mimeType) =>
          file.type.match(mimeType)
      );
      if (valid) {
        this.warnings.push("isMedia");
      }
    },
    validateFileSize(file) {
      const valid = file.size <= this.fileRestrictions.maxSize;
      if (!valid) {
        this.errors.push("maxSize");
      }
      return valid;
    },
  },
};

export default validateFileMixin;

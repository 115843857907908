<template>
  <div class="info-tile" :class="`info-tile--${tileType}`">
    <div class="info-tile__icon-wrapper">
      <SvgIcon
        :icon="tileType === 'warning' ? 'stop-circle' : 'exclamation-triangle'"
        class="info-tile__icon"
      />
    </div>
    <p class="info-tile__copy">
      {{ text }}
    </p>
  </div>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "InfoTile",
  components: { SvgIcon },
  props: {
    text: {
      type: String,
      default: null,
    },
    tileType: {
      type: String,
      default: "info",
    },
  },
};
</script>

<style lang="stylus" scoped>
.info-tile {

  box-shadow $box-shadow-interactive-element
  border-radius $border-radius-default
  padding 20px 40px 20px 30px
  background-color $color-note-info
  display flex

  &__icon-wrapper {
    flex-shrink 0
    text-align center
    text-transform uppercase
    font-size: $font-size.xs
    font-weight bold
    margin-right 20px
  }

  &__icon {
    font-size 32px
    display block
  }

  &--warning {
    background-color $color-note-warning
  }
}
</style>

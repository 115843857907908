<template>
  <svg class="icon" :class="`icon--${icon}`">
    <use :xlink:href="`#icon-${icon}`" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.icon {
  display inline-block
  width 1em
  height 1em
  stroke-width 0
  stroke currentColor
  fill currentColor
  pointer-events none
  flex 0 0 auto
}
</style>

<template>
  <div class="link-field-group grid-row mt--1">
    <div class="col-6">
      <BaseTextInput
        v-model="v$.link.url.$model"
        :label="linkIndex ? null : componentLabels.urlLabel"
        :placeHolder="componentLabels.urlPlaceHolder"
        :validator="v$.link.url"
      />
    </div>
    <div class="col-6 d--flex">
      <BaseTextInput
        v-model="v$.link.text.$model"
        :label="linkIndex ? null : componentLabels.textLabel"
        :placeHolder="componentLabels.textPlaceHolder"
        :validator="v$.link.text"
      />
      <button
        class="link-field-group__remove-button"
        :class="{ 'mt--4': !linkIndex }"
        @click="onRemoveLinkClick(linkIndex)"
      >
        <SvgIcon icon="trash-alt" />
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { url, required } from "@vuelidate/validators";
import { mapState } from "vuex";
import BaseTextInput from "@/components/form/BaseTextInput";
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "LinkFieldGroup",
  components: { SvgIcon, BaseTextInput },
  props: {
    linkIndex: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Object,
      default() {
        return {
          url: null,
          text: null,
        };
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      link: this.modelValue,
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.briefing?.links || {};
    },
  },
  watch: {
    link: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
    modelValue: {
      handler(value) {
        this.link = value;
      },
      deep: true,
    },
  },
  methods: {
    onRemoveLinkClick(indexToRemove) {
      this.$emit("removeLinkAtIndex", indexToRemove);
    },
  },
  emits: ["update:modelValue", "removeLinkAtIndex"],
  validations() {
    return {
      link: {
        url: {
          required,
          url,
        },
        text: {
          required,
        },
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
.link-field-group {
  &__remove-button {
    flex 0 0 auto
    cursor pointer
    padding 8px
    margin-left 16px
    color inherit
    transition color $duration-transition-hover-default ease-in-out

    &:hover {
      color $color-brand-green
    }
  }
}
</style>

<template>
  <div class="user-nav" v-if="user">
    <div class="flyout-nav">
      <span
        class="flyout-nav__main-link"
        :class="{ 'pointer--none': user.teams?.length <= 1 }"
      >
        {{ currentTeam?.name }}
        <SvgIcon v-if="user.teams?.length > 1" icon="caret-down" />
      </span>
      <div v-if="user.teams?.length > 1" class="flyout-nav__flyout-wrapper">
        <nav class="flyout-nav__flyout">
          <button
            v-for="team in user.teams"
            :key="`team-change-link-${team.id}`"
            class="link flyout-nav__flyout-link"
            @click="onTeamChangeClick(team.id)"
          >
            <SvgIcon icon="sign-out-alt" class="link__icon" />
            <span class="link__text">{{ team.name }}</span>
          </button>
        </nav>
      </div>
    </div>
    <div class="flyout-nav">
      <span class="flyout-nav__main-link">
        <UserAvatar />
        <SvgIcon icon="caret-down" />
      </span>
      <div class="flyout-nav__flyout-wrapper">
        <nav class="flyout-nav__flyout">
          <Link
            url="/login"
            icon="sign-out-alt"
            :text="labels?.common?.logout"
            class="flyout-nav__flyout-link"
            @click="logout"
          />
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar";
import SvgIcon from "@/components/common/SvgIcon";
import Link from "@/components/elements/Link";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "HeaderUserNav",
  components: { Link, SvgIcon, UserAvatar },
  computed: {
    ...mapGetters("user", {
      currentTeam: "getCurrentTeam",
    }),
    ...mapState("common", ["labels"]),
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("user", ["changeTeam", "logout"]),
    async onTeamChangeClick(teamId) {
      if (teamId === this.currentTeam) return;
      await this.changeTeam(teamId);
      await this.$router.push("/");
    },
  },
};
</script>

<style lang="stylus" scoped>
.user-nav {
  display flex
  height 100%
  gap 24px
  align-items center
}

.flyout-nav {

  display flex
  height 100%
  position relative

  &__main-link {

    font-weight $font-weight-bold
    display flex
    align-items center
    cursor pointer
    font-size: $font-size.s

    .icon {
      margin-left 8px
    }
  }

  &__flyout-wrapper {
    position absolute
    z-index 10
    top 100%
    padding 0 4px 4px 4px
    right -4px
    overflow hidden
    pointer-events none
  }

  &__flyout {
    background-color $color-background-alternative
    box-shadow $box-shadow-interactive-element
    border-radius 0 0 $border-radius-default $border-radius-default
    border-top 1px solid transparent
    background-clip content-box
    display flex
    flex-direction column
    pointer-events auto
    overflow-y auto
    transform translateY(-100%)
    opacity 0
    transition opacity 0.2s 0.1s ease-out, transform 0.2s ease-in

    ^[0]:hover & {
      transform translateY(0)
      opacity 1
      transition opacity 0.3s ease-in, transform 0.2s ease-out
    }
  }

  &__flyout-link {
    white-space nowrap
    padding 12px 30px 12px 20px
  }
}
</style>

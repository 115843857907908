<template>
  <div class="login container d--flex d--flex-center">
    <div class="login__form-wrapper">
      <h1 class="headline headline--main">
        {{ labels?.onboarding?.headline }}
      </h1>
      <p class="mt--3">{{ labels?.onboarding?.hint }}</p>
      <form action="/" novalidate @submit.prevent="onFormSubmit">
        <BaseTextInput
          v-model="v$.password.$model"
          :validator="v$.password"
          type="password"
          :label="labels?.onboarding?.password"
          name="password"
          class="mt--4"
        />
        <BaseTextInput
          v-model="passwordRepeat"
          :validator="v$.passwordRepeat"
          type="password"
          :label="labels?.onboarding?.passwordRepeat"
          name="passwordRepeat"
          class="mt--3"
          @blur="v$.passwordRepeat.$touch"
        />
        <div v-if="errorMessage" class="mt--3">
          <p class="form-field__error-message">{{ errorMessage }}</p>
        </div>
        <BaseButton
          type="submit"
          :text="labels?.onboarding?.button"
          class="button--black"
          :class="errorMessage ? 'mt--3' : 'mt--5'"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";
import BaseTextInput from "@/components/form/BaseTextInput";
import BaseButton from "@/components/elements/BaseButton";

export default {
  name: "OnBoarding",
  components: { BaseButton, BaseTextInput },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      password: null,
      passwordRepeat: null,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getLoginStatus",
    }),
    ...mapState("common", ["labels"]),
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    ...mapActions("user", ["setPassword"]),
    async onFormSubmit() {
      this.errorMessage = null;
      const isValid = await this.v$.$validate();
      if (!isValid) return;
      const result = await this.setPassword({
        password: this.password,
        token: this.$route.params.id,
      });
      if (result.success) {
        await this.$router.push("/login");
      }
      this.errorMessage = result.errorMessage;
    },
  },
  validations() {
    return {
      password: {
        required,
      },
      passwordRepeat: {
        required,
        passwordRepeat: sameAs(this.password),
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
.login {
  flex 1

  &__form-wrapper {
    width (100% / 3)
  }

}
</style>

const sortAlphabetically = (entries, sortProp, direction = "desc") => {
  const sortedEntries = entries.sort((a, b) => {
    const compareA = sortProp ? a[sortProp] : a;
    const compareB = sortProp ? b[sortProp] : b;
    if (!compareA) return -1;
    if (!compareB) return +1;
    return compareA.localeCompare(compareB);
  });
  return direction === "asc" ? sortedEntries : sortedEntries.reverse();
};

export { sortAlphabetically };

<template>
  <div class="grid-row mt--3">
    <div class="col-5">
      <BaseTextInput
        :placeHolder="labels?.advertorials?.filter?.title.placeHolder"
        icon="search"
        v-model="filter.title"
        class="form-field--filter"
      />
    </div>
    <div class="col-6 col-start-7 d--flex">
      <FilterDropdown
        :label="
          selectedFilters.dashboardStatus
            ? labels?.advertorials?.filter?.dashboardStatus.name
            : labels?.advertorials?.filter?.dashboardStatus.fullySelectedName
        "
        :subLabel="filterSubLabels.dashboardStatus"
        :class="{
          'filter-dropdown--selected': selectedFilters.dashboardStatus,
        }"
        @toggled="(val) => onDropDownToggled('dashboardStatus', val)"
      >
        <FilterCheckboxGroup
          filterKey="dashboardStatus"
          :options="options.dashboardStatus"
          v-model="filter.dashboardStatus"
        />
      </FilterDropdown>
      <FilterDropdown
        :label="
          selectedFilters.publisher
            ? labels?.advertorials?.filter?.publisher.name
            : labels?.advertorials?.filter?.publisher.fullySelectedName
        "
        :subLabel="filterSubLabels.publisher"
        :class="{ 'filter-dropdown--selected': selectedFilters.publisher }"
        @toggled="(val) => onDropDownToggled('publisher', val)"
      >
        <FilterCheckboxGroup
          filterKey="publisher"
          :options="options.publisher"
          :sortAlphabetically="true"
          v-model="filter.publisher"
        />
      </FilterDropdown>
    </div>
  </div>
</template>

<script>
import FilterCheckboxGroup from "@/components/overview/FilterCheckboxGroup";
import FilterDropdown from "@/components/overview/FilterDropdown";
import BaseTextInput from "@/components/form/BaseTextInput";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Filters",
  components: {
    FilterCheckboxGroup,
    FilterDropdown,
    BaseTextInput,
  },
  data() {
    return {
      filter: this.activeFilter || {
        title: null,
        dashboardStatus: this.options?.dashboardStatus,
        publisher: this.options?.publisher,
      },
      activeDropDowns: [],
    };
  },
  computed: {
    ...mapState("common", ["config", "labels"]),
    ...mapState("advertorials", ["advertorials", "activeFilter"]),
    ...mapGetters("common", {
      dashboardStatus: "getDashboardStatus",
    }),
    options() {
      const optionsConfig = {
        dashboardStatus: this.dashboardStatus,
        publisher: this.config.publisher,
      };
      return ["dashboardStatus", "publisher"].reduce(
        (optionsObject, filterKey) => {
          optionsObject[filterKey] = optionsConfig?.[filterKey]
            ? Object.keys(optionsConfig?.[filterKey]).map((id) => {
                return {
                  ...optionsConfig?.[filterKey][id],
                  value: id,
                  selectable: Boolean(
                    this.advertorials.find(
                      (advertorial) => advertorial[filterKey] === id
                    )
                  ),
                };
              })
            : [];
          return optionsObject;
        },
        {}
      );
    },
    selectedFilters() {
      return Object.keys(this.options).reduce(
        (selectedFiltersObject, filterKey) => {
          selectedFiltersObject[filterKey] = Boolean(
            this.options[filterKey]
              .filter((option) => option.selectable)
              .find((option) => !this.filter[filterKey].includes(option.value))
          );
          return selectedFiltersObject;
        },
        {}
      );
    },
    filterSubLabels() {
      return Object.keys(this.selectedFilters).reduce(
        (filterSubLabelsObject, filterKey) => {
          filterSubLabelsObject[filterKey] = this.selectedFilters[filterKey]
            ? this.filter[filterKey].length
              ? this.options[filterKey]
                  .filter(
                    (option) =>
                      option.selectable &&
                      this.filter[filterKey].includes(option.value)
                  )
                  .map((option) => option.name)
                  .join(", ")
              : this.labels.advertorials.filter[filterKey].noneSelected
            : "";
          return filterSubLabelsObject;
        },
        {}
      );
    },
  },
  watch: {
    activeDropDowns: {
      handler(newValue) {
        this.$emit("dropDownsActive", Boolean(newValue.length));
      },
      deep: true,
    },
    filter: {
      handler(newValue) {
        this.setActiveFilter(newValue);
      },
      deep: true,
    },
    options: {
      handler(newValue) {
        Object.keys(newValue).forEach((filterKey) => {
          if (!this.filter[filterKey]) {
            this.filter[filterKey] = newValue[filterKey].map(
              (entry) => entry.value
            );
          }
        });
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.activeFilter && Object.keys(this.activeFilter).length) {
      this.filter = this.activeFilter;
    }
  },
  methods: {
    ...mapActions("advertorials", ["setActiveFilter"]),
    onDropDownToggled(filterName, show) {
      if (show) {
        this.activeDropDowns.push(filterName);
      } else {
        this.activeDropDowns = this.activeDropDowns.filter(
          (entry) => entry !== filterName
        );
      }
    },
  },
  emits: ["dropDownsActive"],
};
</script>

<style scoped></style>

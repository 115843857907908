<template>
  <div class="file-input">
    <ConfirmModal ref="confirmModal"/>
    <template v-if="!medium.file && !medium.mediumId">
      <div class="file-input__input-wrapper">
        <input
            ref="input"
            type="file"
            :name="name"
            @change="onFileInputChange"
            class="file-input__input"
        />
        <BaseButton
            icon="plus"
            class="file-input__button button--inline button--medium button--black"
            :text="labels?.advertorial?.briefing?.files.buttonText"
            @click="$refs.input.click()"
        />
      </div>
      <span v-if="errorMessage" class="form-field__error-message">{{
          errorMessage
        }}</span>
    </template>
    <div v-else class="file-input__file-display">
      <div class="file-input__file-display-text-wrapper">
        <a
            v-if="existingMedium?.url"
            :href="existingMedium.url"
            target="_blank"
            class="file-input__file-display-link"
        >
          {{ filename }}
        </a>
        <span v-else>
          {{ filename }}
        </span>
      </div>
      <button class="file-input__remove-button" @click="onDeleteClick">
        <SvgIcon icon="trash-alt"/>
      </button>
    </div>
    <span v-if="warningMessage" class="form-field__error-warning">{{
        warningMessage
      }}</span>
  </div>
</template>

<script>
import BaseButton from "@/components/elements/BaseButton";
import {mapState} from "vuex";
import SvgIcon from "@/components/common/SvgIcon";
import validateFileMixin from "@/mixins/validateFileMixin";
import ConfirmModal from "@/components/common/ConfirmModal";

export default {
  name: "BaseFileInput",
  components: {SvgIcon, BaseButton, ConfirmModal},
  mixins: [validateFileMixin],
  props: {
    existingMedium: {
      type: [Object, null],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "removeFile"],
  data() {
    return {
      medium: this.existingMedium
          ? {...this.existingMedium}
          : {
            file: null,
          },
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.formFields || {};
    },
    filename() {
      return (
          this.medium?.file?.name ||
          `${this.medium?.filename}.${this.medium?.fileExtension}`
      );
    },
    errorMessage() {
      return this.errors
          .map((error) => {
            let errorMessage = "";
            if (error === "mimeType") {
              errorMessage = this.componentLabels?.errorMessages?.wrongFileType;
            } else if (error === "maxSize") {
              errorMessage = this.componentLabels?.errorMessages?.fileTooBig;
            }
            return errorMessage;
          })
          .join(", ");
    },
    warningMessage() {
      return this.warnings
          .map((warning) => {
            let warningMessage = "";
            if (warning === "isMedia") {
              return this.componentLabels?.warningMessages?.isArticleMedia;
            }
            return warningMessage;
          })
          .join(", ");
    }
  },
  watch: {
    medium: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
  },
  methods: {
    onFileInputChange() {
      this.errors = [];
      const file = this.$refs.input.files[0];
      this.isMedia(file)
      if (this.validateFileMimeType(file) && this.validateFileSize(file)) {
        this.medium.file = file;
      }
    },
    async onDeleteClick() {
      if (await this.$refs.confirmModal.show({
        titleText: "Hinweis!",
        mainText: `Wollen sie wirklich ${this.filename}  löschen?`,
        cancelText: 'Abbrechen',
        confirmText: 'Löschen'
      })) {
        this.$emit("removeFile");
        // this.medium = {
        //   file: null,
        // };
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.file-input {

  &__input-wrapper {
    display inline-block
    position relative
  }

  &__input {
    position absolute
    width 100%
    height 100%
    opacity 0
    cursor pointer
  }

  &__button {
    position relative
  }

  &__file-display {
    background-color $color-background-table
    box-shadow $box-shadow-interactive-element
    border-radius $border-radius-default
    height px-to-rem(58px)
    display flex
    align-items center

    &-text-wrapper {
      padding-left 24px
      padding-right 24px
      font-size: $font-size.M
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
    }

    &-link {
      color $color-brand-green

      &:hover {
        text-decoration underline
      }
    }
  }

  &__remove-button {
    cursor pointer
    margin-left auto
    width 60px
    align-self stretch
    display flex
    align-items center
    justify-content center
    border-left 1px solid $color-border-default
    color inherit
    transition color $duration-transition-hover-default ease-in-out

    &:hover {
      color $color-brand-green
    }
  }
}
</style>

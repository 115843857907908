import advertorialStore from "@/store/modules/advertorial";
import { mapActions, mapGetters } from "vuex";
import {
  FEEDBACK_ENABLED_ADVERTORIAL_STATUS,
  FINALIZE_BRIEFING_ADVERTORIAL_STATUS,
  FINALIZE_FEEDBACK_ENABLED_ADVERTORIAL_STATUS,
  RELEASE_ENABLED_ADVERTORIAL_STATUS,
} from "@/constants";

const advertorialMixin = {
  data() {
    return {
      advertorialId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("advertorial", ["getAdvertorialById","getIsDirty"]),
    advertorial() {
      return this.getAdvertorialById(this.advertorialId);
    },
    isDirty() {
      return this.getIsDirty;
    },
    files() {
      if (!this.purposeType) return null;
      const files = this.advertorial?.files?.[this.purposeType];
      return files?.length ? files : [];
    },
    showFinalizeBriefingButton() {
      return FINALIZE_BRIEFING_ADVERTORIAL_STATUS.includes(
        this.advertorial?.advertorialStatus
      );
    },
    showReleaseButton() {
      return RELEASE_ENABLED_ADVERTORIAL_STATUS.includes(
        this.advertorial?.advertorialStatus
      );
    },
    showFeedbackForm() {
      return FEEDBACK_ENABLED_ADVERTORIAL_STATUS.includes(
        this.advertorial?.advertorialStatus
      );
    },
    showFinalizeFeedbackButton() {
      return FINALIZE_FEEDBACK_ENABLED_ADVERTORIAL_STATUS.includes(
        this.advertorial?.advertorialStatus
      );
    },
    enablePreviewLink() {
      return (
        this.showFeedbackForm ||
        this.showReleaseButton ||
        this.advertorial?.desktopUrl ||
        this.advertorial?.mobileUrl
      );
    },
    enableBadgeLink() {
      return this.advertorial?.badges;
    },
  },
  created() {
    if (!this.$store.hasModule("advertorial")) {
      this.$store.registerModule("advertorial", advertorialStore);
    }
    if (!this.advertorial) {
      this.fetchAdvertorial(this.advertorialId);
    }
  },
  methods: {
    ...mapActions("advertorial", [
      "fetchAdvertorial",
      "updateAdvertorial",
      "patchAdvertorial",
      "postAdvertorialMediums",
      "deleteAdvertorialMedium",
      "updateIsDirty",
    ]),
    checkIfDirty(event) {
      if(this.isDirty){
        event.preventDefault()
        event.returnValue = true
      }
    },
    async onSaveClick() {
      const isValid = await this.validateForm();
      if (isValid) {
        await this.postAdvertorialMediums(this.advertorialId);
        await this.patchAdvertorial(this.advertorialId);
        this.updateIsDirty({
          value: false
        })
      } else {
        return Promise.reject();
      }
    },
    async onRemoveFileAtIndex(indexToRemove) {
      const files = [...this.files];
      const mediumId = files[indexToRemove]?.mediumId;
      if (mediumId) {
        await this.deleteAdvertorialMedium({
          advertorialId: this.advertorialId,
          mediumId,
        });
      }
      files.splice(indexToRemove, 1);
      this.updateFiles(files);
    },
    updateFileAtIndex(indexToUpdate, file, value) {
      const files = [...this.files];
      if (value?.mediumId) {
        value.changed = true;
      }
      files[indexToUpdate] = {
        ...file,
        ...value,
      };
      this.updateFiles(files);
    },
    updateBriefing(prop, value) {
      this.updateAdvertorial({
        advertorialId: this.advertorialId,
        category: "briefing",
        prop,
        value,
      });
        this.updateIsDirty({
          value: true
        })
    },
    updateFiles(value) {
      this.updateAdvertorial({
        advertorialId: this.advertorialId,
        category: "files",
        prop: this.purposeType,
        value,
      });
      this.updateIsDirty({
        value: true
      })

    },
    async validateForm() {
      const isValid = !this.v$ || (await this.v$.$validate());
      if (!isValid) {
        this.scrollToFirstError();
      }
      return isValid;
    },
    scrollToFirstError() {
      this.$nextTick(() => {
        this.$el.querySelector(".form-field--error")?.scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    emptyFileObject(type) {
      return { tempId: `tmp-${+new Date()}`, purposeType: this.purposeType,type: type};
    },

  },
};

export default advertorialMixin;

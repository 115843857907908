<template>
  <div
    class="image-input form-field form-field--small"
    :class="{ 'form-field--error': errors.length }"
  >
    <div class="form-field__wrapper">
      <div class="form-field__label">
        {{ labels?.label }}
        <template v-if="editable">
          <span
            v-for="restriction in ['dimensions', 'maxSize', 'mimeType']"
            :key="`restriction-label-${restriction}`"
            class="form-field__info"
            :class="{
              'form-field__info--error': this.errors.includes(restriction),
            }"
          >
            {{ restriction === "maxSize" ? labels?.restrictions?.["maxSize"].replace("%MAXFILESIZE%",Math.floor(this.fileRestrictions.maxSize / (1024*1024))) : labels?.restrictions?.[restriction] }}
          </span>
        </template>
      </div>
      <div class="form-field__input-wrapper">
        <label class="file-upload" :class="{ 'file-upload--preview': binary }">
          <input
            v-if="editable"
            ref="input"
            class="file-upload__input"
            type="file"
            @change="onFileInputChange"
            @mouseenter="onInputMouseEnter"
            @dragover="onInputDragOver"
            @dragleave="onInputDragLeave"
            @mouseleave="onInputMouseLeave"
            @drop="onInputDrop"
          />
          <div
            class="file-upload__input-replacement"
            v-if="editable"
            :class="{ 'file-upload__input-replacement--hover': inputHovered }"
          >
            <SvgIcon icon="image-inverted" class="icon file-upload__icon" />
            <BaseButton
              :text="labels?.buttonText"
              icon="file-upload"
              class="
                button--inline button--small button--black
                file-upload__button
              "
            />
          </div>
          <img
            v-if="tempImage"
            :src="tempImage"
            class="file-upload__image-preview"
          />
        </label>
        <span v-if="errors.length" class="form-field__error-message">
          {{ labels.errorMessage }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
import BaseButton from "@/components/elements/BaseButton";
import validateFileMixin from "@/mixins/validateFileMixin";

export default {
  name: "BaseImageInput",
  mixins: [validateFileMixin],
  components: { BaseButton, SvgIcon },
  props: {
    labels: {
      type: Object,
      default() {
        return {};
      },
    },
    imageSource: {
      type: String,
      default: null,
    },
    restrictions: {
      type: Object,
      default() {
        return {
          mimeTypes: ["image/jpg", "image/jpeg", "image/png"],
          dimensions: {
            width: 1280,
            height: 720,
          },
        };
      },
    },
  },
  data() {
    return {
      binary: null,
      tempImage: this.imageSource,
      inputHovered: false,
      lockInputHover: false,
    };
  },
  emits: ["update", "error"],
  computed: {
    editable() {
      return !this.imageSource;
    },
  },
  mounted() {
    //prevent loading file in browser when it's dropped outside of input
    ["drop", "dragover"].forEach((eventName) => {
      document.addEventListener(eventName, (e) => {
        if (e.target.type !== "file") {
          e.preventDefault();
        }
      });
    });
  },
  watch: {
    binary(newValue) {
      this.$emit("update", newValue);
    },
    errors(newValue) {
      this.$emit("error", newValue);
    },
  },
  methods: {
    onFileInputChange(e) {
      this.errors = [];
      const files = e.target.files;
      if (
        files[0] &&
        this.validateFileMimeType(files[0]) &&
        this.validateFileSize(files[0])
      ) {
        const reader = new FileReader();
        const img = new Image();

        img.addEventListener("load", () => {
          this.tempImage = img.src;
          if (this.validateImage(img)) {
            this.binary = files[0];
          }
        });
        reader.addEventListener("load", (e) => {
          img.src = e.target.result;
        });
        reader.readAsDataURL(files[0]);
      }
    },
    onInputDragLeave() {
      this.inputHovered = false;
    },
    onInputDragOver() {
      this.inputHovered = true;
    },
    onInputDrop() {
      this.inputHovered = false;
      this.lockInputHover = true;
    },
    onInputMouseEnter() {
      if (!this.lockInputHover) {
        this.inputHovered = true;
      }
    },
    onInputMouseLeave() {
      this.inputHovered = false;
      this.lockInputHover = false;
    },
    validateImage(image) {
      let errorCount = 0;
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      if (
        this.fileRestrictions.dimensions &&
        (imageWidth < this.fileRestrictions.dimensions.width ||
          imageHeight < this.fileRestrictions.dimensions.height)
      ) {
        errorCount++;
        this.errors.push("dimensions");
      }

      return !errorCount;
    },
  },
};
</script>

<style lang="stylus" scoped>
.file-upload {

  display block
  overflow hidden
  position relative
  aspect-ratio 1200 / 627

  &__input {
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    opacity 0
    z-index 10
    cursor pointer

    &--readonly {
      pointer-events none
    }
  }

  &__input-replacement {
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    flex-direction column
    background-color $color-background-form-field
    box-shadow inset 0 0 0 3px transparent
    border 1px dashed $color-border-default
    transition background-color $duration-transition-hover-default ease-in-out, border-color $duration-transition-hover-default ease-in-out, box-shadow $duration-transition-hover-default ease-in-out

    ^[0]--preview & {
      z-index 8
      border none
      background-color rgba($color-background-form-field, 0.85)
      opacity 0
      transition opacity $duration-transition-hover-default ease-in-out
    }

    &--hover {
      background-color rgba($color-brand-green, 0.1)
      border-color transparent
      box-shadow inset 0 0 0 3px $color-brand-green

      ^[0]--preview & {
        opacity 1
        box-shadow none
        background-color rgba($color-background-form-field, 0.85)
        will-change opacity
      }
    }
  }

  &__icon {
    font-size 48px
  }

  &__note {
    margin-top 8px
    font-size $font-size.m
    font-weight $font-weight-bold
  }

  &__button {
    margin-top 20px

    &--cancel-upload {
      margin-top 50px
    }
  }

  &__image-preview {
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display block
    background-color $color-lightest-gray
    z-index 5
    object-fit contain
  }

  &__canvas-wrapper {
    position absolute
    width 1px
    height 1px
    overflow hidden
    pointer-events none
    opacity 0
  }

  &__button-wrapper {
    position absolute
    right 0
    bottom 0
    z-index 15
    display flex
  }


}
</style>

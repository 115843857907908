<template>
  <section class="article-preview mt--6">
    <div class="article-preview__button-wrapper">
      <button
        type="button"
        class="article-preview__device-button"
        :class="{
          'article-preview__device-button--active': activeDevice === 'desktop',
        }"
        @click="activeDevice = 'desktop'"
      >
        <SvgIcon icon="desktop" class="article-preview__device-button-icon" />
      </button>
      <button
        type="button"
        class="article-preview__device-button"
        :class="{
          'article-preview__device-button--active': activeDevice === 'mobile',
        }"
        @click="activeDevice = 'mobile'"
      >
        <SvgIcon
          icon="mobile-alt"
          class="article-preview__device-button-icon"
        />
      </button>
      <a :href="activePreviewUrl" target="_blank" class="article-preview__link">
        In neuem Tab Öffnen
        <SvgIcon icon="external-link-alt" />
      </a>
      <a v-if="desktopLiveUrl" :href="desktopLiveUrl" target="_blank" class="article-preview__link article-preview__link-live">
        Live Version
        <SvgIcon icon="external-link-alt" />
      </a>
      <slot name="shareButton"></slot>
    </div>
    <iframe
      v-if="activeDevice === 'desktop'"
      :src="desktopUrl"
      class="article-preview__desktop-iframe mt--3"
    ></iframe>
    <div v-else class="article-preview__mobile grid-row mt--3">
      <div class="article-preview__mobile-content-wrapper col-8">
        <div class="article-preview__mobile-icon-wrapper">
          <svg
            class="article-preview__mobile-icon"
            viewBox="0 0 311 622"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M265.757514,0.0531346251 L45.3946853,0.0531346251 C15.6673193,0.0531346251 0.922486402,17.6809526 0.922486402,45.2020856 L0.922486402,576.803438 C0.922486402,605.880405 17.2123699,621.959833 45.3946853,621.959833 L265.757514,621.959833 C291.354841,621.959833 310.229713,605.366756 310.229713,576.803438 L310.229713,45.2020856 C310.237141,20.264083 295.48488,0.0531346251 265.757514,0.0531346251 Z M156.53396,606.346823 C141.074805,606.346823 128.537716,593.809734 128.537716,578.350579 C128.537716,562.883912 141.074805,550.346823 156.53396,550.346823 C172.008138,550.346823 184.537716,562.8764 184.537716,578.350579 C184.537716,593.809734 172.008138,606.346823 156.53396,606.346823 Z M291.443978,532.160691 L19.5076612,532.160691 L19.5076612,76.1376353 L291.443978,76.1376353 L291.443978,532.160691 Z"
              fill="#2E2E2E"
              fill-rule="nonzero"
            />
          </svg>
          <iframe
            :src="mobileUrl"
            class="article-preview__mobile-iframe"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "ArticlePreview",
  components: { SvgIcon },
  props: {
    desktopUrl: {
      type: String,
      default: null,
    },
    mobileUrl: {
      type: String,
      default: null,
    },
    desktopLiveUrl: {
      type: String,
      default: null,
    },
    shareUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeDevice: "desktop",
    };
  },
  computed: {
    activePreviewUrl() {
      return this.activeDevice === "desktop" ? this.desktopUrl : this.mobileUrl;
    },
  },
};
</script>

<style lang="stylus" scoped>
.article-preview {

  &__button-wrapper {
    display flex
    align-items flex-end
    column-gap 24px
  }

  &__device-button {
    font-size 36px
    cursor pointer

    &-icon {
      display block
    }

    &--active {
      color $color-brand-green
    }
  }

  &__link {
    font-size: $font-size.s
    font-weight $font-weight-bold
    text-transform uppercase
    transition color $duration-transition-hover-default ease-in-out

    &:hover {
      color $color-brand-green
    }
    &-live{
      margin-left auto
    }
  }

  &__desktop {

    &-iframe {
      border none
      width 100%
      aspect-ratio 1083 / 885
      background-color $color-background-alternative
    }
  }

  &__mobile {

    &-content-wrapper {
      display flex
      align-items center
      justify-content center
      background-color $color-background-alternative
      padding 40px
    }

    &-icon {
      width 400px
      height auto
      display block

      &-wrapper {
        position relative
      }
    }

    &-iframe {
      position absolute
      border none
      width 350px
      height 586px
      top 98px
      left 25px
    }
  }


}
</style>

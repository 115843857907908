import axios from "axios";
import { API_URL_LOCAL, API_URL_PROD } from "@/constants";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? API_URL_LOCAL : API_URL_PROD,
  headers: {
    common: {
      Accept: "application/json",
    },
  },
});

export default instance;

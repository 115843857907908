<template>
  <section class="imprint-form">
    <BaseTextInput
      v-model="v$.company.$model"
      :label="componentLabels?.company?.label"
      :validator="v$.company"
      :readonly="!editable"
      :is-required="editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="name"
      :label="componentLabels?.name?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="street"
      :label="componentLabels?.street?.label"
      :readonly="!editable"
      :validator="v$.street"
      :is-required="editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="additionalAddress"
      :label="componentLabels?.additionalAddress?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="zip"
      :label="componentLabels?.zip?.label"
      :readonly="!editable"
      :is-required="editable"
      :validator="v$.zip"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="city"
      :label="componentLabels?.city?.label"
      :readonly="!editable"
      :validator="v$.city"
      :is-required="editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="linkText"
      :label="componentLabels?.linkText?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="v$.linkUrl.$model"
      :label="componentLabels?.linkUrl?.label"
      :validator="v$.linkUrl"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="v$.email.$model"
      :label="componentLabels?.email?.label"
      :validator="v$.email"
      :is-required="editable"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="phone"
      :label="componentLabels?.phone?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="telefax"
      :label="componentLabels?.telefax?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="ustId"
      :label="componentLabels?.ustId?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="taxNumber"
      :label="componentLabels?.taxNumber?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="registeredMember"
      :label="componentLabels?.registeredMember?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <BaseTextInput
      v-model="supervisoryAuthority"
      :label="componentLabels?.supervisoryAuthority?.label"
      :readonly="!editable"
      class="form-field--small mt--3"
    />
    <div class="mt--6">
      <h4 class="headline headline--section">
        {{ componentLabels?.info?.label }}
      </h4>
    </div>
    <BaseTextEditor v-model="info" :readonly="!editable" class="mt--1" />
  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, url, required } from "@vuelidate/validators";
import BaseTextInput from "@/components/form/BaseTextInput";
import { mapActions, mapGetters, mapState } from "vuex";
import BaseTextEditor from "@/components/form/BaseTextEditor";

export default {
  name: "ImprintForm",
  components: { BaseTextEditor, BaseTextInput },
  props: {
    imprintId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    ...mapGetters("imprint", ["getImprintById", "getImprintListByTeamId"]),
    ...mapGetters("user", ["getCurrentTeam"]),
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.imprint || {};
    },
    imprint() {
      return this.getImprintById(this.imprintId);
    },
    editable() {
      // was needed to prevent changing imprints in use
      // requirements have been updated since, so it's no longer necessary
      // https://doku.plan-a.de/uportal/status-flow#berechtigungen
      return true;
    },
    company: {
      get() {
        return this.imprint?.company;
      },
      set(value) {
        this.updateStore("company", value);
      },
    },
    name: {
      get() {
        return this.imprint?.name;
      },
      set(value) {
        this.updateStore("name", value);
      },
    },
    street: {
      get() {
        return this.imprint?.street;
      },
      set(value) {
        this.updateStore("street", value);
      },
    },
    additionalAddress: {
      get() {
        return this.imprint?.additionalAddress;
      },
      set(value) {
        this.updateStore("additionalAddress", value);
      },
    },
    zip: {
      get() {
        return this.imprint?.zip;
      },
      set(value) {
        this.updateStore("zip", value);
      },
    },
    city: {
      get() {
        return this.imprint?.city;
      },
      set(value) {
        this.updateStore("city", value);
      },
    },
    linkText: {
      get() {
        return this.imprint?.linkText;
      },
      set(value) {
        this.updateStore("linkText", value);
      },
    },
    linkUrl: {
      get() {
        return this.imprint?.linkUrl;
      },
      set(value) {
        this.updateStore("linkUrl", value);
      },
    },
    email: {
      get() {
        return this.imprint?.email;
      },
      set(value) {
        this.updateStore("email", value);
      },
    },
    phone: {
      get() {
        return this.imprint?.phone;
      },
      set(value) {
        this.updateStore("phone", value);
      },
    },
    telefax: {
      get() {
        return this.imprint?.telefax;
      },
      set(value) {
        this.updateStore("telefax", value);
      },
    },
    ustId: {
      get() {
        return this.imprint?.ustId;
      },
      set(value) {
        this.updateStore("ustId", value);
      },
    },
    taxNumber: {
      get() {
        return this.imprint?.taxNumber;
      },
      set(value) {
        this.updateStore("taxNumber", value);
      },
    },
    registeredMember: {
      get() {
        return this.imprint?.registeredMember;
      },
      set(value) {
        this.updateStore("registeredMember", value);
      },
    },
    supervisoryAuthority: {
      get() {
        return this.imprint?.supervisoryAuthority;
      },
      set(value) {
        this.updateStore("supervisoryAuthority", value);
      },
    },
    info: {
      get() {
        return this.imprint?.info;
      },
      set(value) {
        this.updateStore("info", value);
      },
    },
  },
  watch: {
    imprintId: {
      handler(newValue) {
        if (newValue && !this.imprint) {
          this.fetchImprint(newValue);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("imprint", ["fetchImprint", "updateImprint"]),
    updateStore(prop, value) {
      this.updateImprint({
        imprintId: this.imprintId,
        prop,
        value: typeof value === "string" ? value.trim() : value,
      });
    },
  },
  validations() {
    return {
      company: {
        required,
      },
      street: {
        required,
      },
      zip: {
        required,
      },
      city: {
        required,
      },
      email: {
        email,
        required,
      },
      linkUrl: {
        url,
      },
    };
  },
};
</script>
<style lang="stylus" scoped>
.imprint-form {
  background-color $color-background-table
  box-shadow $box-shadow-interactive-element
  border-radius $border-radius-default
  padding 32px
}
</style>

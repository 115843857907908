<template>
  <footer class="page-footer mt--10">
    <div class="container">
      <nav class="page-footer__navigation">
        <Link
          v-for="(link, index) in links"
          :key="`page-footer-link-${index}`"
          :url="link.url"
          :text="link.linkText"
        />
      </nav>
    </div>
  </footer>
</template>

<script>
import Link from "../elements/Link";

export default {
  name: "PageFooter",
  components: {
    Link,
  },
  props: {
    links: {
      type: Array,
      default() {
        return [
          {
            url: "/service/imprint",
            linkText: "Impressum",
          },
        ];
      },
    },
  },
};
</script>

<style lang="stylus" scoped>



.page-footer {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: $color-background-footer;
  border-top: 1px solid $color-border-default;

  &__navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: $font-size.s;
    gap 0.5em 32px
  }


}
</style>

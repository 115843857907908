const state = {
  showLockLayer: false,
};

const getters = {};

const actions = {
  toggleLockLayer({ commit, state }, showLockLayer) {
    commit("SET_SHOW_LOCK_LAYER", showLockLayer ?? !state.showLockLayer);
  },
};

const mutations = {
  SET_SHOW_LOCK_LAYER: (state, payload) => {
    state.showLockLayer = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="container mt--8">
    <div class="grid-row">
      <div class="col-8 col-start-3">
        <h1 class="headline headline--main t--center">
          404 - Seite nicht gefunden
        </h1>
        <h4 class="headline mt--6">Es tut uns leid...</h4>
        <section class="mt--5">
          <p class="mt--1">Die gewünschte Seite wurde nicht gefunden.</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PathNotFound",
};
</script>

<style scoped></style>

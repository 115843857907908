<template>
  <div class="page-wrapper" :class="$route.meta?.pageWrapperModifierClass">
    <SvgSprite />
    <PageHeader :userIsLoggedIn="userIsLoggedIn" />
    <main class="main-content">
      <router-view />
    </main>
    <PageFooter />
    <LockLayer />
  </div>
</template>

<script>
import "@/styles/global.styl";
import LockLayer from "@/components/common/LockLayer";
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import SvgSprite from "@/components/common/SvgSprite";
import commonStore from "@/store/modules/common";
import userStore from "@/store/modules/user";
import uiStore from "@/store/modules/ui";

import { mapActions, mapGetters, mapState } from "vuex";
import axiosInstance from "@/utils/axiosInstance";

export default {
  components: { LockLayer, SvgSprite, PageFooter, PageHeader },
  computed: {
    ...mapGetters("user", {
      userIsLoggedIn: "getLoginStatus",
    }),
    ...mapState("common", ["config"]),
  },
  created() {
    if (!this.$store.hasModule("user")) {
      this.$store.registerModule("user", userStore);
    }
    if (!this.$store.hasModule("common")) {
      this.$store.registerModule("common", commonStore);
    }
    if (!this.$store.hasModule("ui")) {
      this.$store.registerModule("ui", uiStore);
    }
    this.fetchLabels();
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          await this.logout();
          await this.$router.push("/login");
        }
        return Promise.reject(error);
      }
    );
  },
  methods: {
    ...mapActions("common", ["fetchConfig", "fetchLabels"]),
    ...mapActions("user", ["fetchUser", "logout"]),
  },
};
</script>

<style lang="stylus"></style>

<template>
  <div v-if="advertorial && imprintList" class="container">
    <div class="grid-row">
      <div class="col-8">
        <h1 class="headline headline--main mt--8">{{ advertorial.title }}</h1>
        <TabNav/>
        <template v-if="imprintList.length">
          <BaseRadioButton
              v-model="currentImprintType"
              :inputValue="'existing'"
              class="mt--5"
          >{{ componentLabels.selectImprint?.existingImprint }}
          </BaseRadioButton>
          <template v-if="currentImprintType === 'existing'">
            <select
                v-if="imprintList?.length > 1"
                v-model="currentImprintId"
                class="mt--3"
            >
              <option
                  v-for="imprintOption in imprintList"
                  :key="`imprint-option-${imprintOption.id}`"
                  :value="imprintOption.id"
              >
                {{ imprintOption.title }}
              </option>
            </select>
            <p v-else-if="currentImprintId" class="mt--3 fw--bold">
              {{
                imprintList?.find((imprint) => imprint.id === currentImprintId)
                    ?.title
              }}
            </p>
            <ImprintForm :imprintId="currentImprintId" class="mt--3"/>
          </template>
          <BaseRadioButton
              v-model="currentImprintType"
              :inputValue="'new'"
              class="mt--5"
          >{{ componentLabels.selectImprint?.newImprint }}
          </BaseRadioButton>
        </template>
        <p v-else class="mt--3">
          {{ componentLabels.selectImprint?.newImprint }}
        </p>
        <ImprintForm
            v-if="currentImprintType === 'new'"
            :imprintId="'new'"
            class="mt--3"
        />
        <div class="d--flex mt--10">
          <BaseButton
              :text="labels.common.save"
              icon="save"
              class="button--inline button--black"
              @click="onSaveClick"
              :class="{'button--disabled' : !imprintIsDirty}"
          />
          <BaseButton
              v-if="showFinalizeBriefingButton"
              :text="componentLabels.finalizeBriefing"
              icon="telegram-plane"
              class="button--inline ml--auto"
              @click="onFinalizeBriefingClick"
          />
          <BaseButton
              v-else-if="enablePreviewLink"
              :text="componentLabels.continueLink.title"
              icon="arrow-right"
              iconPosition="right"
              class="button--inline ml--auto"
              @click="onContinueClick"
          />
        </div>
        <Feedback
            :advertorialId="advertorialId"
            :advertorialStatus="advertorial?.advertorialStatus"
            :showFinalizeFeedbackButton="showFinalizeFeedbackButton"
        />
      </div>
      <div class="col-4 mt--4">
        <StatusBox/>
      </div>
    </div>
    <unsaved-change-modal ref="confirmModal"/>
  </div>
</template>

<script>
import advertorialMixin from "@/mixins/advertorialMixin";
import TabNav from "@/components/advertorial/TabNav";
import StatusBox from "@/components/advertorial/StatusBox";
import imprintStore from "@/store/modules/imprint";
import BaseButton from "@/components/elements/BaseButton";
import {mapActions, mapGetters, mapState} from "vuex";
import ImprintForm from "@/components/advertorial/ImprintForm";
import useVuelidate from "@vuelidate/core";
import BaseRadioButton from "@/components/form/BaseRadioButton";
import {toDateStringWithTime} from "@/utils/formatString";
import UnsavedChangeModal from "@/components/common/unsavedChangeModal.vue";
import Feedback from "@/components/advertorial/Feedback.vue";

export default {
  name: "Imprint",
  components: {Feedback, UnsavedChangeModal, BaseRadioButton, ImprintForm, TabNav, StatusBox, BaseButton},
  mixins: [advertorialMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  async beforeRouteLeave(to, from, next) {
    const leaveSite = () => {
      this.resetDirtyImprintList()
      window.removeEventListener("beforeunload", this.checkImprintIsDirty);
      next();
    };

    if (this.imprintIsDirty) {
      const modal = await this.$refs.confirmModal.show({
        titleText: "Ungespeicherte Änderungen",
        mainText: ` Sie haben Änderungen vorgenommen,die bisher nicht gespeichert wurden. Sollen diese gespeichert werden?`,
        cancelText: "Änderungen verwerfen",
        confirmText: "Speichern",
      });
      if (modal !== "interrupt") {
        if (modal) {
          await this.onSaveClick();
          leaveSite();
        } else {
          if (this.getImprintIsDirtyById(this.currentImprintId)) {
            await this.fetchImprint(this.currentImprintId)
          }
          leaveSite();
        }
      } else {
        next(false);
      }
    } else {
      leaveSite();
    }
  },
  data() {
    return {
      currentImprintId:
          this.advertorialImprintId || this.imprintList?.[0]?.id || "new",
      currentImprintType: "existing",
    };
  },
  computed: {
    ...mapGetters("imprint", ["getImprintListByTeamId", "getImprintIsDirtyById"]),
    ...mapGetters("user", ["getCurrentTeam"]),
    ...mapState("common", ["labels"]),
    selectedImprintChanged() {
      return this.advertorialImprintId !== this.currentImprintId
    },
    imprintIsDirty() {
     return this.getImprintIsDirtyById(this.currentImprintId) || this.selectedImprintChanged
    },
    componentLabels() {
      return this.labels?.advertorial?.imprint || {};
    },
    advertorialImprintId() {
      return this.advertorial?.imprintId;
    },
    imprintList() {
      return this.getImprintListByTeamId(this.getCurrentTeam?.id)?.map(
          (imprint) => {
            return {
              ...imprint,
              title: `${imprint.company} (${toDateStringWithTime(
                  imprint.createdAt
              )})`,
            };
          }
      );
    },
  },
  watch: {
    advertorialImprintId: {
      handler(newValue) {
        if (newValue) {
          this.currentImprintId = newValue;
          this.currentImprintType = "existing";
        }
      },
      immediate: true,
    },
    currentImprintId: {
      handler(newValue) {
        if (newValue === "new") {
          this.currentImprintType = "new";
        }
      },
      immediate: true,
    },
    imprintList: {
      handler(newValue) {
        if (!this.advertorialImprintId && newValue?.length) {
          this.currentImprintId = this.imprintList?.[0]?.id;
          this.currentImprintType = "existing";
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (!this.$store.hasModule("imprint")) {
      this.$store.registerModule("imprint", imprintStore);
    }
    if (!this.imprintList) {
      await this.fetchImprintList(this.getCurrentTeam.id);
    }
    if (this.currentImprintId === "new") {
      this.currentImprintType = "new";
    }

    const bindUnloadEvent = () => {
      window.addEventListener("beforeunload", this.checkImprintIsDirty);
      window.removeEventListener("click", bindUnloadEvent)
    }
    window.addEventListener("click", bindUnloadEvent)

    this.resetDirtyImprintList();

  },
  methods: {
    ...mapActions("advertorial", ["approveBriefing"]),
    ...mapActions("imprint", [
      "fetchImprintList",
      "fetchImprint",
      "updateImprint",
      "putImprint",
      "postImprint",
      "setImprintIsDirtyById",
      "unsetImprintIsDirtyById",
      "resetDirtyImprintList"
    ]),
    checkImprintIsDirty(event) {
      if (this.imprintIsDirty) {
        event.preventDefault();
        event.returnValue = true
      }
    },
    async onSaveClick() {
      const isValid = await this.validateForm();
      if (isValid) {
        if (this.currentImprintType === "new") {
          await this.postImprint({
            advertorialId: this.advertorialId,
            teamId: this.getCurrentTeam.id,
          });
        } else {

          if (this.getImprintIsDirtyById(this.currentImprintId)) {
            await this.putImprint(this.currentImprintId);
            this.unsetImprintIsDirtyById(this.currentImprintId)

          }
          if (this.selectedImprintChanged) {
            this.updateAdvertorial({
              advertorialId: this.advertorialId,
              prop: "imprintId",
              value: this.currentImprintId,
            });
            await this.patchAdvertorial(this.advertorialId);
            await this.fetchAdvertorial(this.advertorialId)
          }


        }

      } else {
        return Promise.reject();
      }
    },
    onFinalizeBriefingClick() {
      this.onSaveClick().then(() => {
        this.approveBriefing(this.advertorialId);
      });
    },
    onContinueClick() {
      this.onSaveClick().then(() => {
        this.resetDirtyImprintList()
        this.$router.push(
            `/advertorial/${this.advertorialId}/vorschau`
        );
      });
    },
  },
};
</script>
<style lang="stylus" scoped>

select {
  appearance auto
  background $color-white
  padding 8px 16px
  border 1px solid $color-border-default
}
</style>

<template>
  <span
    class="tooltip"
    :class="[{ 'tooltip--init': positionChecked }, { 'tooltip--fixed': fixed }]"
    v-cloak
    @mouseenter="onMouseEnter"
  >
    <slot name="toggle">
      <span class="tooltip__button">
        <SvgIcon icon="info-circle" />
      </span>
    </slot>
    <span
      ref="content"
      class="tooltip__content"
      :class="{ 'tooltip__content--left': isOutOfViewPort }"
      :style="fixed ? `left: ${posX}; top: ${posY}` : ''"
    >
      <slot></slot>
    </span>
  </span>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
export default {
  name: "Tooltip",
  components: { SvgIcon },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      positionChecked: false,
      isOutOfViewPort: false,
      posX: "auto",
      posY: "auto",
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.positionChecked = false;
      this.isOutOfViewPort = false;
    });
  },
  methods: {
    checkPosition() {
      const rect = this.$refs.content.getBoundingClientRect();
      return rect.right > window.innerWidth;
    },
    onMouseEnter() {
      if (!this.positionChecked) {
        this.isOutOfViewPort = this.checkPosition();
        this.positionChecked = true;
      }
      if (this.fixed) {
        this.setPos();
      }
    },
    setPos() {
      const clientRect = this.$el.getBoundingClientRect();
      this.posX = this.fixed ? `${clientRect.left}px` : "auto";
      this.posY = this.fixed ? `${clientRect.top}px` : "auto";
    },
  },
};
</script>

<style lang="stylus" scoped>
.tooltip {

  display inline-flex
  position relative
  margin-left 6px
  white-space normal

  &__button {
    font-size px-to-rem(16)
    display flex
    cursor pointer
  }

  &__content {
    position absolute
    display block
    z-index 10
    top 8px
    left 20px
    width px-to-rem(240px)
    font-size: $font-size.xs
    font-weight $font-weight-regular
    background-color $color-background-tooltip
    color $color-text-inverted
    padding 8px
    border-radius 3px
    opacity 0
    visibility hidden
    pointer-events none
    transition opacity $duration-transition-hover-default ease-in-out,
    visibility 0s $duration-transition-hover-default

    &--left {
      left auto
      right px-to-rem(22)
    }

    ^[0]--init:hover & {
      opacity 1
      visibility visible
      pointer-events initial
      transition opacity $duration-transition-hover-default ease-in-out,
      visibility 0s 0s
    }

    &:before {
      content ""
      width 10px
      height 100%
      position absolute
      top -10px
      left -10px
      display block
      background transparent
    }

    a {
      text-decoration underline
    }
  }

  &--icon {
    & ^[0]__content {
      top calc(100% + 8px)
      left 50%
      transform translateX(-50%)

      &--left {
        left auto
        right 0
        transform none
      }
    }
  }

  &--button {
    @extend .tooltip--icon;
    display: flex;
  }

  &--inline {
    & ^[0]__content {
      display: inline-block;
      white-space: nowrap;
      width: auto;
    }
  }

  &--fixed {
    &:hover {
      z-index: 10;
    }

    & ^[0]__content {
      position: fixed;
      top: auto;
      left: auto;
      margin-top: 1.2em;
      margin-left: 0;
      transform: translateX(-50%);
    }
  }

  &--absolute {
    position: absolute;
    margin: 0;
  }

}
</style>

import { toDateString, toDateStringWithTime } from "@/utils/formatString";

const formatStringMixin = {
  methods: {
    toDateString() {
      return toDateString(...arguments);
    },
    toDateStringWithTime() {
      return toDateStringWithTime(...arguments);
    },
  },
};

export default formatStringMixin;

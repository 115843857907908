<template>
  <div
    v-if="user"
    class="user-avatar"
    :class="`user-avatar--color-${user?.userColorIndex}`"
    :data-initials="user?.initials"
  >
    <img v-if="user.image" :src="user.image.src" class="user-avatar__image" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UserAvatar",
  computed: {
    ...mapState("user", ["user"]),
  },
};
</script>

<style lang="stylus" scoped>

.user-avatar {
  position relative
  flex 0 0 auto
  width 40px
  height 40px
  border-radius 50%
  background-color $color-user-fallback
  overflow hidden
  display flex
  justify-content center
  align-items center

  &::after {
    content attr(data-initials)
    color $color-white
    font-size: $font-size.xs
    font-weight $font-weight-bold
    text-transform uppercase
  }

  &--color-1 {
    background-color $color-user-1
  }

  &--color-2 {
    background-color $color-user-2
  }

  &--color-3 {
    background-color $color-user-3
  }

  &--color-4 {
    background-color $color-user-4
  }

  &--color-5 {
    background-color $color-user-5
  }

  &--color-6 {
    background-color $color-user-6
  }

  &--color-7 {
    background-color $color-user-7
  }

  &--color-8 {
    background-color $color-user-8
  }

  &--large {
    border 3px solid $white

    &::after {
      font-size: $font-size-xxxl
    }
  }

  &__image {
    display block
    position absolute
    width 100%
    height 100%
    top 0
    left 0
    object-fit cover
  }

}
</style>

<template>
  <div class="status-box" :class="[{ 'status-box--open': accordionIsOpen }]">
    <Accordion
        :includeToggle="true"
        :initiallyOpen="accordionIsInitiallyOpen"
        :closedHeight="'180px'"
        @accordionToggled="(val) => (accordionIsOpen = val)"
    >
      <template v-slot:toggle>
        <div class="status-box__toggle">
          <h4
              class="headline headline--section"
              v-text="
              accordionIsOpen
                ? componentLabels.statusTitle
                : componentLabels.toggleTitle
            "
          ></h4>
          <SvgIcon icon="caret-down" class="status-box__toggle-icon"/>
        </div>
      </template>
      <template v-slot:default>
        <div v-if="accordionIsOpen">
          <div
              class="status-box__status-item mt--3"
              :class="[`status-box__status-item--${additonalStatus?.state}`,additonalStatus?.extraClass]"
          >
            <span class="status-box__status-item-icon"></span>
            {{ additonalStatus ? additonalStatus.label : 'Der Artikel ist veröffentlicht' }}
          </div>
          <ul class="status-box__status-wrapper" :class="{'mt--3' : !additonalStatus}">
            <li
                v-for="status in status.filter((e) => !e.isAdditional)"
                :key="`status-box-item-${status.id}`"
                class="status-box__status-item"
                :class="[`status-box__status-item--${status.state}`,status?.extraClass]"
            >
              <span class="status-box__status-item-icon"></span>
              {{ status.label }}
            </li>
          </ul>
          <h5 class="headline headline--section mt--4">
            {{ componentLabels.historyTitle }}
          </h5>
          <ul v-if="messages?.length" class="status-box__history-wrapper mt--2">
            <li
                v-for="message in messages"
                :key="`status-box-history-item-${message.id}`"
                class="status-box__message"
            >
              <h5 class="fw--bold fs--m status-box__message-text">
                {{ message.message }}
              </h5>
              <div class="d--flex fs--xs">
                <span class="status-box__message-date">
                  {{ toDateStringWithTime(message.createdAt) }}
                </span>
                <span class="status-box__message-author">
                  {{ message.createdBy }}
                </span>
              </div>
            </li>
          </ul>
          <p v-else class="status-box__empty-history-note mt--2">
            {{ componentLabels.emptyHistoryNote }}
          </p>
        </div>
        <div v-else>
          <div
              class="
              status-box__status-item
              status-box__status-item--active
              status-box__status-item--single
              mt--3
            "
              :class="{'--red': advertorial?.dashboardStatus === 'expired'}"
          >
            <span class="status-box__status-item-icon"></span>
            <template v-if="!advertorial?.dashboardStatus">
              {{ componentLabels.status[advertorial.advertorialStatus] }}

            </template>
            <template v-else>
              {{ status.find((e) => e.id === advertorial?.dashboardStatus)?.label }}
            </template>

          </div>
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Accordion from "@/components/common/BaseAccordion";
import SvgIcon from "@/components/common/SvgIcon";
import advertorialMixin from "@/mixins/advertorialMixin";
import formatStringMixin from "@/mixins/formatStringMixin";

export default {
  name: "StatusBox",
  components: {SvgIcon, Accordion},
  mixins: [advertorialMixin, formatStringMixin],
  props: {
    accordionIsInitiallyOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      accordionIsOpen: this.accordionIsInitiallyOpen,
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
    ...mapGetters("messages", ["getMessagesByAdvertorialId"]),
    messages() {
      return this.getMessagesByAdvertorialId(this.advertorialId);
    },
    additonalStatus(){
      return this.status.find((e) => e.isAdditional)
    },
    componentLabels() {
      return this.labels?.advertorial?.statusBox || {};
    },
    status() {
      const activeStatusIndex = Object.keys(
          this.componentLabels?.status
      ).findIndex(
          (statusKey) => statusKey === this.advertorial.advertorialStatus
      );
      const allStatus = this.componentLabels?.status && !isNaN(activeStatusIndex)
          ? Object.keys(this.componentLabels.status)?.map(
              (statusKey, statusIndex) => {
                return {
                  id: statusKey,
                  label: this.componentLabels.status[statusKey],
                  state:
                      statusIndex < activeStatusIndex
                          ? "finished"
                          : statusIndex === activeStatusIndex
                              ? "active"
                              : "not-started",
                };
              }
          )
          : [];
      if (this.advertorial.dashboardStatus === "online") {
        allStatus.push({
          id: "online",
          label: "Der Artikel ist veröffentlicht",
          state: "active",
          extraClass: "--additional",
          isAdditional: true,
        })
      }
      if (this.advertorial.dashboardStatus === "expired") {
        allStatus.push({
          id: "expired",
          label: "Der Artikel ist abgelaufen",
          state: "active",
          extraClass: ['--red','--additional'],
          isAdditional: true,
        })
      }
      return allStatus
    },
  },
  watch: {
    advertorialId: {
      handler(newVal) {
        if (newVal && !this.messages) {
          this.fetchAdvertorialMessages({advertorialId: newVal});
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("messages", ["fetchAdvertorialMessages"]),
  },
};
</script>

<style lang="stylus" scoped>
.status-box {
  position sticky
  top 0
  background-color $color-background-table
  box-shadow $box-shadow-interactive-element
  border-radius $border-radius-default
  padding 16px 32px 32px

  &__toggle {
    display flex
    align-items center
    justify-content space-between

    &-icon {
      font-size 1.5em
      transition transform 0.2s ease-in-out
      transform-origin center

      .accordion--active & {
        transform rotate(180deg)
      }
    }
  }

  &__status-wrapper {
    position relative
    display flex
    flex-direction column-reverse
    gap 16px
    overflow hidden

    &::before {
      content ""
      position absolute
      width 2px
      height 100%
      top 0
      left 11px // 24px / 2 - 1px
      background-color $color-light-gray
    }
  }

  &__status-item {
    position relative
    display flex
    align-items center
    color $color-text-gray
    font-size: $font-size.xs
    &.--additional{
      margin-bottom 16px
      &::before{
        display none !important
      }
    }
    &:first-child {
      align-items flex-end
    }

    &:last-child {
      align-items flex-start
    }

    &-icon {
      position relative
      z-index 1
      width 24px
      height 24px
      flex 0 0 auto
      margin-right 16px
      background-color $color-background-table

      &::before {
        content ''
        display block
        width 100%
        height 100%
        border-radius 50%
        background-color $color-light-gray
      }
    }

    &--active,
    &--finished {
      &.--red {
        color $color-bright-red

        & ^[1]-icon {
          &::before {
            background-color $color-bright-red
            border 2px solid $color-bright-red
          }
        }
      }
      color $color-brand-green
      font-weight $font-weight-bold
      font-size: $font-size.m

      & ^[1]-icon {
        &::before {
          z-index 1
          background-color $color-brand-green
          background-clip content-box
          padding 3px
          border 2px solid $color-brand-green
        }
      }
    }

    &--active {

      &::before {
        content ""
        position absolute
        width 2px
        height 100vh
        top 0
        left 11px // 24px / 2 - 1px
        background-color $color-brand-green
      }
    }

    &--single {

      &:first-child,
      &:last-child {
        align-items center
      }

      &::before {
        display none
      }
    }
  }

  &__message {

    &-text {
      margin-bottom 4px
      white-space pre-line
    }

    &-date {
      color $color-text-gray
      margin-right 8px
    }

    & + & {
      margin-top 8px
    }
  }

  &__empty-history-note {
    color $color-text-gray
    font-size: $font-size.xs
  }

}
</style>

<template>
  <section class="image-figure">
    <ConfirmModal ref="confirmModal" />
    <header class="image-figure__header">
      <template v-if="medium?.mediumId">
        <Status
          :status="medium?.status || 'draft'"
          :showTitle="false"
          class="image-figure__status"
        />
        <div class="image-figure__number">#{{ medium.mediumId }}</div>
        <Tooltip>{{ componentLabels.tooltip }}</Tooltip>
      </template>
      <button
        v-if="editable"
        class="image-figure__remove-button"
        @click="onDeleteClick"
      >
        <SvgIcon icon="trash-alt" />
      </button>
    </header>
    <main class="image-figure__content-wrapper">
      <BaseImageInput
        :labels="componentLabels?.image"
        :imageSource="existingMedium?.source"
        :validator="v$.mediaErrors"
        @error="(val) => (mediaErrors = val)"
        @update="onImageUpdate"
        v-if="type == 'image'"
      />
      <BaseVideoInput
          :labels="componentLabels?.video"
          :videoSource="existingMedium?.url"
          :validator="v$.mediaErrors"
          @error="(val) => (mediaErrors = val)"
          @update="onImageUpdate"
        v-if="type == 'video'"
      />
      <BaseTextInput
        v-model="v$.medium.title.$model"
        :label="componentLabels?.caption?.label"
        :is-required="hasImage"
        :readonly="!editable"
        :validator="v$.medium.title"
        class="form-field--small mt--3"
      />
      <BaseTextInput
        v-model="v$.medium.externalUrl.$model"
        :label="componentLabels?.url?.label"
        :readonly="!editable"
        :validator="v$.medium.externalUrl"
        class="form-field--small mt--3"
      />
      <BaseTextInput
        v-model="v$.medium.filename.$model"
        :label="componentLabels?.fileName?.label"
        :readonly="!editable"
        :is-required="hasImage"
        :validator="v$.medium.filename"
        class="form-field--small mt--3"
      />
      <BaseTextInput
        v-model="medium.credit"
        :label="componentLabels?.copyright?.label"
        :readonly="!editable"
        class="form-field--small mt--3"
      />
      <BaseTextInput
        v-model="medium.licenseExpiration"
        :label="componentLabels?.licenseDate?.label"
        :readonly="!editable"
        type="date"
        :min="getTomorrowDateInIsoFormat()"
        class="form-field--small mt--3"
      />
      <BaseTextarea
        v-model="medium.notice"
        :label="componentLabels?.commentary?.label"
        :readonly="!editable"
        class="form-field--small mt--3"
      />
    </main>
  </section>
</template>

<script>
import Status from "@/components/elements/Status";
import Tooltip from "@/components/common/Tooltip";
import SvgIcon from "@/components/common/SvgIcon";
import BaseTextInput from "@/components/form/BaseTextInput";
import { mapState } from "vuex";
import BaseImageInput from "@/components/form/BaseImageInput";
import BaseVideoInput from "@/components/form/BaseVideoInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import { requiredIf, url } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import removeFileExtension from "@/utils/removeFileExtension";
import ConfirmModal from "@/components/common/ConfirmModal";

const mustBeEmpty = (value) => !value.length;

export default {
  name: "MediaFigure",
  components: {
    ConfirmModal,
    BaseTextarea,
    BaseImageInput,
    BaseVideoInput,
    BaseTextInput,
    SvgIcon,
    Tooltip,
    Status,
  },
  emits: ["update:modelValue", "removeFile"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    existingMedium: {
      type: Object,
      default: null,
    },
    imageNumber: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      medium: this.existingMedium
        ? { ...this.existingMedium }
        : {
            file: null,
            title: null,
            externalUrl: null,
            credit: null,
            licenseExpiration: null,
            notice: null,
            filename: null,
          },
      mediaErrors: [],
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.media[this.type] || {};
    },
    editable() {
      return !this.medium?.status || this.medium.status === "draft";
    },
    hasImage() {
      return Boolean(this.existingMedium?.source || this.medium?.file);
    },
  },
  watch: {
    medium: {
      handler(newValue) {
        this.$emit("update:modelValue", newValue);
      },
      deep: true,
    },
  },
  methods: {
    onImageUpdate(file) {
      this.medium.file = file;
      this.medium.filename = `${removeFileExtension(this.medium?.file?.name)}`;
    },
    async onDeleteClick() {
      if (
        await this.$refs.confirmModal.show({
          titleText: "Hinweis!",
          mainText: `Wollen sie wirklich ${this.medium.filename}  löschen?`,
          cancelText: "Abbrechen",
          confirmText: "Löschen",
        })
      ) {
        this.$emit("removeFile");
      }
    },
    getTomorrowDateInIsoFormat() {
      const tomorrow = new Date(
        Date.now() +
          24 * 60 * 60 * 1000 -
          new Date().getTimezoneOffset() * 60 * 1000
      );
      return tomorrow.toISOString().substr(0, 10);
    },
  },
  validations() {
    return {
      medium: {
        title: {
          requiredIfHasImage: requiredIf(this.hasImage),
        },
        filename: {
          requiredIfHasImage: requiredIf(this.hasImage),
        },
        externalUrl: {
          url,
        },
      },
      mediaErrors: {
        mustBeEmpty,
      },
    };
  },
};
</script>

<style lang="stylus" scoped>

.image-figure {
  background-color $color-background-table
  box-shadow $box-shadow-interactive-element
  border-radius $border-radius-default

  &__header {
    height 60px
    display flex
    align-items center
    padding-left 32px
    border-bottom 1px solid $color-border-default
    font-size $font-size.l
  }

  &__number {
    margin-left 20px
    margin-right 8px
  }

  &__remove-button {
    cursor pointer
    margin-left auto
    width 60px
    align-self stretch
    display flex
    align-items center
    justify-content center
    border-left 1px solid $color-border-default
    font-size 20px
    color inherit
    transition color $duration-transition-hover-default ease-in-out

    &:hover {
      color $color-brand-green
    }
  }

  &__content-wrapper {
    padding 32px
  }

}
</style>

<template>
  <div
    class="form-field form-field--textarea"
    :class="[
      { 'form-field--has-value': modelValue },
      { 'form-field--error': hasError },
    ]"
  >
    <label class="form-field__wrapper">
      <div v-if="label" class="form-field__label">{{ label }}</div>
      <span class="form-field__input-wrapper">
        <textarea
          :name="name"
          :value="modelValue"
          :placeholder="placeHolder"
          :readonly="readonly"
          class="form-field__input"
          :class="[{ 'form-field__input--readonly': readonly }]"
          @input="$emit('update:modelValue', $event.target.value)"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
        <SvgIcon v-if="icon" :icon="icon" class="form-field__icon" />
      </span>
      <span v-if="errorMessage" class="form-field__error-message"
        >{{ errorMessage }}
      </span>
    </label>
  </div>
</template>

<script>
import BaseTextInput from "@/components/form/BaseTextInput";

export default {
  name: "BaseTextarea",
  extends: BaseTextInput,
};
</script>
<style lang="stylus">

.form-field {

  &--textarea {

    & ^[0]__input {
      height px-to-rem(120px)
      padding-top 12px
      padding-bottom 12px
      resize vertical
    }
  }
}
</style>

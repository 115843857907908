<template>
  <div class="text-accordion" :class="{ 'text-accordion--active': isOpen }">
    <div ref="content" class="text-accordion__content" :style="contentStyles">
      <slot :is-open="isOpen"></slot>
    </div>
    <button
      v-if="showToggle"
      type="button"
      class="text-accordion__toggle"
      @click="toggleContent"
    >
      <SvgIcon icon="caret-down" class="text-accordion__toggle-icon" />
    </button>
  </div>
</template>
<script>
import BaseAccordion from "./BaseAccordion";
import SvgIcon from "@/components/common/SvgIcon";

export default {
  components: { SvgIcon },
  extends: BaseAccordion,
  computed: {
    showToggle() {
      return parseFloat(this.openedHeight) > parseFloat(this.closedHeight);
    },
  },
  mounted() {
    this.setContentHeight();
  },
};
</script>

<style lang="stylus" scoped>

.text-accordion {
  &__content {
    position: relative;
    z-index: 1;
    transition: max-height 0.3s ease-in-out;

    &--no-transition {
      transition: none;
    }
  }

  &__toggle {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4px 0;
    font-size: 22px;
    background-color: transparent;
    cursor: pointer;
    transition: color $duration-transition-hover-default ease-in-out;

    &:hover {
      color: $color-brand-green;
    }
  }

  &__toggle-icon {
    transform: rotate(0deg);
    transition: transform 0s 0.3s ease-in-out;

    ^[0]--active & {
      transform: rotate(180deg);
    }
  }

  &--messages {
    ^[0]__content {
      background-color: $color-background-table;
      box-shadow: $box-shadow-interactive-element;
      border-radius: $border-radius-default;
      scrollbar-default();
    }
  }
}
</style>

<template>
  <div class="checkbox">
    <label class="checkbox__wrapper">
      <input
        class="checkbox__input"
        type="checkbox"
        :value="inputValue"
        v-model="model"
      />
      <span class="checkbox__label">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    modelValue: {
      type: Array,
      default: null,
      required: true,
    },
    inputValue: {
      type: String,
      default: null,
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style lang="stylus" scoped>

$icon-encoded-checkbox-check = 'data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2032%2032%22%3E%0A%3Cpath%20fill%3D%22%2395c11c%22%20d%3D%22M11.232%2028.897c-0.91%201.001-2.444%201.015-3.398%200.061l-7.102-7.103c-0.967-0.967-0.981-2.522-0.005-3.498l0.312-0.312c0.964-0.965%202.531-0.962%203.485-0.008l4.879%204.879%2017.955-19.753c0.924-1.017%202.479-1.108%203.501-0.179l0.326%200.297c1.009%200.918%201.1%202.462%200.18%203.474l-20.131%2022.144z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E'

.checkbox {

  & + & {
    margin-top 8px
  }

  &__input {
    opacity 0
    position absolute
  }

  &__label {
    display flex
    align-items center

    &::before {
      content ""
      display flex
      flex 0 0 auto
      justify-content center
      align-items center
      width 20px
      height 20px
      border 2px solid $color-middle-gray
      background-color $color-white
      border-radius 2px
      margin-right 16px
      margin-top $before-margin-top
    }

    &:empty::before {
      margin-right 0
    }

    ^[0]__input:checked + & {
      &::before {
        content url($icon-encoded-checkbox-check)
      }
    }

    ^[0]--readonly & {
      &::before,
      &::after {
        opacity $opacity-disabled
      }
    }
  }

}
</style>

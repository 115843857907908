<template>
  <div class="reset-password container d--flex d--flex-center">
    <div class="reset-password__form-wrapper">

      <template v-if="!mailSend">
        <h1 class="headline headline--main">{{ labels?.password_reset?.sendNewToken?.headline }}</h1>
        <p class="mt--3">{{ labels?.password_reset?.sendNewToken?.description }}</p>

        <form action="/" novalidate @submit.prevent="onFormSubmit">
          <BaseTextInput
              v-model="email"
              @blur="v$.email.$validate()"
              :validator="v$.email"
              type="email"
              :label="labels?.password_reset?.sendNewToken?.email"
              name="email"
              class="mt--5"
          />
          <BaseButton
              type="submit"
              :text="labels?.password_reset?.sendNewToken?.button"
              class="button mt--3"
              :class="errorMessage ? 'mt--3' : 'mt--5'"
          />
        </form>
      </template>

      <p class="t--center" v-else>{{ labels?.password_reset?.sendNewToken?.success }}</p>
    </div>
  </div>
</template>


<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import useVuelidate from "@vuelidate/core";
import {mapActions, mapState} from "vuex";
import BaseButton from "@/components/elements/BaseButton.vue";
import {email, required} from "@vuelidate/validators";

export default {
  name: "resetPassword",
  components: {BaseButton, BaseTextInput},
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
  },
  data() {
    return {
      email: null,
      password: null,
      passwordRepeat: null,
      mailSend: false,
      errorMessage: null,
      setPasswordSuccess: false,
      token: this.$route.params.token,
    }
  },
  methods: {
    ...mapActions("user", ["resetPassword"]),
    async onFormSubmit() {
      this.errorMessage = null;
      const isValid = await this.v$.$validate();
      if (!isValid) return;
      console.log(this.email)
      const result = await this.resetPassword({
        email: this.email,
      });
      if (result.success) {
        this.mailSend = true
      }

      this.errorMessage = result?.errorMessage;
      return result;
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
}
</script>


<style scoped lang="stylus">
.reset-password {
  flex 1

  &__form-wrapper {
    width (100% / 2)
  }
}
</style>
<template>
  <Teleport to="#teleport-modal">
    <div class="lock-layer" :class="{ 'lock-layer--active': active }"></div>
  </Teleport>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LockLayer",
  computed: {
    ...mapState("ui", {
      active: "showLockLayer",
    }),
  },
};
</script>

<style lang="stylus" scoped>
.lock-layer {
  position fixed
  z-index 500
  top 0
  left 0
  width 100%
  height 100%
  display flex
  justify-content center
  align-items center
  background-color $color-background-modal-backdrop
  opacity 0
  visibility hidden
  transition opacity $duration-transition-modal-default ease-in-out, visibility 0s $duration-transition-modal-default

  &::after {
    content url($image-loading-spinner)
    display block
    width 110px
    height 110px
    animation rotate 1s linear infinite
  }

  &--active {
    opacity 1
    visibility visible
    transition opacity $duration-transition-modal-default ease-in-out, visibility 0s 0s
  }
}

@keyframes rotate {
  from {
    transform rotate(0deg)
  }
  to {
    transform rotate(360deg)
  }
}
</style>

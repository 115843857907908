<template>
  <nav class="tab-nav mt--4 fs--l">
    <RouterLink
      :to="`/advertorial/${$route.params.id}/briefing`"
      class="tab-nav__link"
      :title="componentLabels.briefing"
    >
      {{ componentLabels.briefing }}
    </RouterLink>
    <RouterLink
      :to="`/advertorial/${$route.params.id}/medien`"
      class="tab-nav__link"
      :title="componentLabels.media"
    >
      {{ componentLabels.media }}
    </RouterLink>
    <RouterLink
      :to="`/advertorial/${$route.params.id}/impressum`"
      class="tab-nav__link"
      :title="componentLabels.imprint"
    >
      {{ componentLabels.imprint }}
    </RouterLink>
    <RouterLink
      v-if="enablePreviewLink"
      :to="`/advertorial/${$route.params.id}/vorschau`"
      class="tab-nav__link"
      :title="componentLabels.preview"
    >
      {{ componentLabels.preview }}
    </RouterLink>
    <RouterLink
      v-if="enableBadgeLink"
      :to="`/advertorial/${$route.params.id}/badge`"
      class="tab-nav__link"
      :title="componentLabels.badge"
    >
      {{ componentLabels.badge }}
    </RouterLink>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import advertorialMixin from "@/mixins/advertorialMixin";

export default {
  name: "TabNav",
  mixins: [advertorialMixin],
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.nav || {};
    },
  },
};
</script>

<style lang="stylus" scoped>
.tab-nav {

  display flex
  gap 40px
  position relative
  padding-bottom 12px

  &::after {
    content ""
    position absolute
    bottom 0
    left 0
    width 100%
    height 2px
    background-color $color-light-gray
  }

  &__link {
    position relative
    color transparent
    font-weight $font-weight-bold
    white-space nowrap
    transition color 0.1s ease-in-out

    &::before {
      content attr(title)
      position absolute
      font-weight $font-weight-regular
      color $color-text-gray
      transition color 0.1s ease-in-out
    }

    &::after {
      content ""
      position absolute
      z-index 2
      bottom -12px
      left 0
      width 100%
      height 2px
      background-color $color-text-default
      transform scale(0)
      transform-origin center
      transition transform $duration-transition-hover-default ease-in-out
    }

    &:hover,
    &.router-link-active {
      color $color-text-default
      font-weight $font-weight-bold

      &::before {
        color transparent
      }

      &::after {
        transform scale(1)
      }
    }
  }
}
</style>

import axiosInstance from "@/utils/axiosInstance";
import { unFlattenObject } from "@/utils/unflatenObject";

const state = {
  labels: {},
  config: {},
  hasBriefing: false,
};

const getters = {
  getDashboardStatus(state) {
    if (!state.config.dashboardStatus || !state.labels.status?.dashboard) {
      return null;
    } else {
      return state.config.dashboardStatus.reduce((statusObject, statusKey) => {
        statusObject[statusKey] = {
          name: state.labels.status.dashboard[statusKey],
        };
        return statusObject;
      }, {});
    }
  },
  getHasBriefing(state){
    return state.hasBriefing
  },
  getBriefingConfig(state){
    return state.config.briefingInformation
  }
};

const actions = {
  async fetchConfig({ commit }) {
    try {
      const result = await axiosInstance.get("secured-configs");
      commit("SET_CONFIG", result.data);
    } catch (e) {
      console.warn("fetchConfig failed", e);
    }
  },
  async fetchLabels({ commit }) {
    try {
      const result = await axiosInstance.get("unsecured-configs/labels");
      commit("SET_LABELS", result.data?.[0]?.config);
    } catch (e) {
      console.warn("fetchLabels failed", e);
    }
  },
  updateHasBriefing({ commit }) {
    commit("SET_HASBRIEFING", true);
  },
};

const mutations = {
  SET_CONFIG: (state, payload) => {
    state.config = payload.reduce((commonConfigObject, entry) => {
      commonConfigObject[entry.identifier] = entry.config;
      return commonConfigObject;
    }, {});
  },
  SET_LABELS: (state, payload) => {
    state.labels = unFlattenObject(payload);
  },
  SET_HASBRIEFING: (state, payload) => {
    state.hasBriefing = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <label class="radio-button">
    <input
      type="radio"
      class="radio-button__input"
      :value="inputValue"
      v-model="model"
    />
    <span class="radio-button__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "BaseRadioButton",
  props: {
    modelValue: {
      type: String,
      default: null,
      required: true,
    },
    inputValue: {
      type: String,
      default: null,
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style lang="stylus" scoped>
.radio-button {
  position relative
  display block
  cursor pointer

  &--hidden {
    visibility hidden
    opacity 0
  }

  & + & {
    margin-top 8px
  }

  &__input {
    opacity 0
    position absolute
  }

  &__label {
    display flex
    align-items center

    &::before {
      content ''
      flex 0 0 auto

      display flex
      justify-content center
      align-items center

      width 20px
      height 20px

      border 2px solid $color-middle-gray
      border-radius 50%
      margin-right 18px
    }

    ^[0]__input:checked + & {
      &::before {
        content ''
        background-color $color-brand-green
        background-clip content-box
        padding 3px
      }
    }
  }

  &--readonly,
  &--disabled {
    pointer-events none
  }

  &--disabled {
    opacity $opacity-disabled
  }

}
</style>

<template>
  <div class="accordion" :class="{ 'accordion--active': isOpen }">
    <div v-if="includeToggle" class="accordion__toggle" @click="toggleContent">
      <slot name="toggle"></slot>
    </div>
    <div
      ref="content"
      class="accordion__content"
      :style="contentStyles"
      :class="{ 'accordion__content--no-transition': preventTransition }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseAccordion",
  props: {
    closedHeight: {
      type: String,
      default: "0px",
    },
    contentClass: {
      type: String,
      default: null,
    },
    defaultOverflow: {
      type: String,
      default: "hidden",
    },
    includeToggle: {
      type: Boolean,
      default: false,
    },
    initiallyOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.initiallyOpen,
      showOverflow: this.initiallyOpen,
      openedHeight: "none",
      preventTransition: false,
    };
  },
  computed: {
    contentStyles() {
      return {
        maxHeight: this.isOpen ? this.openedHeight : this.closedHeight,
        overflow: this.showOverflow ? "visible" : this.defaultOverflow,
      };
    },
  },
  watch: {
    isOpen(newValue) {
      this.$emit("accordionToggled", newValue);
    },
    initiallyOpen(newValue) {
      this.isOpen = newValue;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.setContentHeight();
    });

    this.$refs.content.addEventListener("transitionend", () => {
      if (this.isOpen) {
        this.showOverflow = true;
      }
    });

    new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          this.preventTransition = true;
          this.setContentHeight();
        }
      });
    }).observe(this.$refs.content, {
      childList: true,
      subtree: true,
    });
  },
  methods: {
    setContentHeight() {
      if (this.$el.offsetParent) {
        this.openedHeight = this.$refs.content.scrollHeight + "px";
      }
    },
    toggleContent() {
      const toggle = () => {
        this.preventTransition = false;
        this.isOpen = !this.isOpen;
        if (!this.isOpen) {
          this.showOverflow = false;
        }
      };
      if (this.openedHeight === "none") {
        this.setContentHeight();
        this.$nextTick(toggle);
      } else {
        toggle();
      }
    },
  },
  emits: ["accordionToggled"],
};
</script>

<style lang="stylus">
.accordion {

  &__toggle {
    cursor pointer
  }

  &__content {
    transition max-height 0.3s ease-in-out

    &--no-transition {
      transition none
    }
  }
}
</style>

import axiosInstance from "@/utils/axiosInstance";
import { ADVERTORIALS_FILTER_CONFIG } from "@/constants";
import { sortAlphabetically } from "@/utils/sort";

const state = {
  advertorials: [],
  activeFilter: null,
  activeSorting: {
    prop: "startDate",
    direction: "desc",
  },
};

const getters = {
  getAdvertorialsByTeam:
    (state, getters, rootState, rootGetters) => (teamId) => {
      return state.advertorials.filter(
        (advertorial) =>
          advertorial.teamId ===
          (teamId ?? rootGetters["user/getCurrentTeam"]?.id)
      );
    },
  getFilteredAdvertorials(state, getters) {
    const allAdvertorialsForCurrentTeam = getters.getAdvertorialsByTeam();
    let filteredAdvertorials;

    if (!state.activeFilter) {
      filteredAdvertorials = allAdvertorialsForCurrentTeam;
    } else {
      const activeFilters = Object.fromEntries(
        Object.entries(state.activeFilter).filter((entries) =>
          Boolean(entries[1])
        )
      );
      filteredAdvertorials = allAdvertorialsForCurrentTeam.filter(
        (advertorial) => {
          return Object.keys(activeFilters).every((activeFilterKey) => {
            const activeFilter = {
              value: activeFilters[activeFilterKey],
              ...ADVERTORIALS_FILTER_CONFIG[activeFilterKey],
            };
            let matches = true;
            let queryRx;
            switch (activeFilter.type) {
              case "string":
                queryRx = new RegExp(
                  activeFilter.value.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"),
                  "i"
                );
                matches = activeFilter.props.find((prop) => {
                  return queryRx.test(advertorial[prop]);
                });
                break;

              case "dateRange":
                break;

              default:
                //defaults to 'array'
                matches = activeFilter.props.every((prop) =>
                  activeFilter.value.includes(advertorial[prop])
                );
            }
            return matches;
          });
        }
      );
    }
    return state.activeSorting
      ? sortAlphabetically(
          filteredAdvertorials,
          state.activeSorting.prop,
          state.activeSorting.direction
        )
      : filteredAdvertorials;
  },
};

const actions = {
  async fetchAdvertorials({ commit }, teamId) {
    try {
      const result = await axiosInstance.get("advertorials", {
        params: {
          teamId: teamId,
        },
      });
      commit(
        "SET_ADVERTORIALS",
        result.data.map((advertorial) => {
          return {
            ...advertorial,
            teamId,
          };
        })
      );
    } catch (e) {
      console.warn("fetchAdvertorials failed", e);
    }
  },
  setActiveFilter({ commit }, filter) {
    commit("SET_ACTIVE_FILTER", filter);
  },
  setActiveSorting({ commit }, sorting) {
    commit("SET_ACTIVE_SORTING", sorting);
  },
};

const mutations = {
  SET_ADVERTORIALS: (state, payload) => {
    state.advertorials = payload;
  },
  SET_ACTIVE_FILTER: (state, payload) => {
    state.activeFilter = payload;
  },
  SET_ACTIVE_SORTING: (state, payload) => {
    state.activeSorting = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="container">
    <Messages />
    <Advertorials />
  </div>
</template>

<script>
// @ is an alias to /src
import Messages from "@/components/overview/Messages";
import Advertorials from "@/components/overview/Advertorials";

export default {
  name: "Home",
  components: {
    Advertorials,
    Messages,
  },
};
</script>

<template>
  <div class="form-field form-field--copy copy-field">
    <div v-if="label" class="form-field__label">{{ label }}</div>
    <div class="d--flex">
      <textarea
        :value="copyString"
        readonly
        class="form-field__input"
        @dblclick="copy"
      />
      <button type="button" class="copy-field__button" @click="copy">
        <SvgIcon icon="copy" />
      </button>
    </div>
    <p
      class="copy-field__success-message mt--1"
      :class="{ '-active': showCopySuccessMessage }"
    >
      {{ copySuccessMessage }}
    </p>
  </div>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "CopyField",
  components: { SvgIcon },
  props: {
    copyString: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    copySuccessMessage: {
      type: String,
      default: "Löpt",
    },
  },
  data() {
    return {
      showCopySuccessMessage: false,
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.copyString);
      this.showCopySuccessMessage = true;
      setTimeout(() => {
        this.showCopySuccessMessage = false;
      }, 3000);
    },
  },
};
</script>

<style lang="stylus" scoped>

.copy-field {

  &__button {
    cursor pointer
    margin-left 8px
    width 60px
    height 60px
    align-self flex-end
    display flex
    flex 0 0 auto
    align-items center
    justify-content center
    background-color $color-background-table
    border 1px solid $color-border-default
    border-radius $border-radius-default
    font-size 24px
    color inherit
    transition color $duration-transition-hover-default ease-in-out

    &:hover {
      color $color-brand-green
    }
  }

  &__success-message {
    font-size $font-size.s
    opacity 0
    transition opacity 2s ease-out

    &.-active {
      opacity 1
      transition opacity 0s
    }
  }
}

.form-field {

  &--copy {

    & ^[0]__input {
      padding-top 12px
      padding-bottom 12px
      resize none
      height auto
      min-height 140px
      font-family monospace
      font-size 1.2rem
    }
  }
}
</style>

<template>
  <div class="status" :class="`status--${status}`">
    <div class="status__icon"></div>
    <div v-if="showTitle" class="status__title">
      {{ dashboardStatus?.[status]?.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Status",
  props: {
    status: {
      type: String,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("common", {
      dashboardStatus: "getDashboardStatus",
    }),
  },
};
</script>

<style lang="stylus" scoped>
.status {

  display flex
  align-items center

  &__icon {
    flex 0 0 auto
    width 16px
    height 16px
    border-radius 50%

    ^[0]--new &,
    ^[0]--draft & {
      background-color $color-status-new
    }

    ^[0]--production & {
      background-color $color-status-production
    }

    ^[0]--online &,
    ^[0]--in_use & {
      background-color $color-status-online
    }

    ^[0]--expired & {
      background-color $color-status-expired
    }
  }

  &__title {
    margin-left 12px

    ^[0]--small & {
      margin-left 8px
    }
  }

}
</style>

<template>
  <div
    class="filter-dropdown"
    :class="{ 'filter-dropdown--active': active }"
    v-click-outside="onClickOutside"
  >
    <div class="filter-dropdown__toggle" @click="active = !active">
      <div class="filter-dropdown__label">
        <span>{{ label }}</span>
        <SvgIcon icon="caret-down" class="filter-dropdown__icon" />
      </div>
      <span v-if="subLabel" class="filter-dropdown__sub-label"
        >{{ subLabel }}
      </span>
    </div>
    <div class="filter-dropdown__content" v-show="active">
      <slot :active="active"></slot>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "FilterDropdown",
  components: { SvgIcon },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  emits: ["toggled"],
  props: {
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  watch: {
    active(newValue) {
      this.$emit("toggled", newValue);
    },
  },
  methods: {
    onClickOutside() {
      this.active = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.filter-dropdown {
  flex 1 1 0
  min-width 0
  background-color $color-background-alternative
  user-select none
  position relative

  & + & {
    margin-left 3px
  }

  &:first-child {
    border-radius $border-radius-default 0 0 $border-radius-default
  }

  &:last-child {
    border-radius 0 $border-radius-default $border-radius-default 0
  }

  &:only-child {
    border-radius $border-radius-default
  }

  &:hover:not(&--active) {
    background-color rgba($color-background-alternative, 0.5)
  }

  &__toggle {
    height px-to-rem(60px)
    box-shadow $box-shadow-interactive-element
    display flex
    flex-direction column
    align-items center
    justify-content center
    padding 8px 16px
    cursor pointer
  }

  &__label {
    width 100%
    display flex
    align-items center
    justify-content center
    font-size: $font-size.m
    font-weight $font-weight-bold
  }

  &__icon {
    margin-left 8px
  }

  &__sub-label {
    display block
    width 100%
    font-size: $font-size.xs
    text-align center
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
  }

  &__content {
    min-width 100%
    background $color-background-alternative
    position absolute
    top calc(100% + 10px)
    left 0
    border-radius $border-radius-default
    box-shadow $box-shadow-interactive-element
    z-index 1
    padding 32px
    white-space nowrap
  }

  &--selected {
    & ^[0]__toggle {
      background-color rgba($color-brand-green, 0.15)
    }
  }

  &--active {
    & ^[0]__toggle {
      background-color transparent
    }

    & ^[0]__label {
      color $color-brand-green
    }
  }

  &--checkbox {
    & ^[0]__content {
      padding-right 8px
    }
  }


  &:last-of-type {

    & ^[0]__content {
      left auto
      right 0
    }
  }

}
</style>
